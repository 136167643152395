import { Table } from "@mui/material";

interface CustomTableProps {
    children?: React.ReactNode;
}

export default function CustomTable(props: CustomTableProps) {
    return (
        <Table size="small">
            {props.children}
        </Table>
    )
}