
import React, { useCallback, useContext, useEffect, useState } from "react";

import './Supliers.css';

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";



import PlusIcon from '@heroicons/react/24/solid/PlusIcon';

import { Box, Button, Container, Divider, IconButton, Modal, Paper, Stack, SvgIcon, Table, TableBody, TableContainer, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material";
import debounce from 'lodash/debounce';
import { useNavigate } from "react-router-dom";
import CustomTableCell from '../../../../components/elements/CustomTableCell';
import Paginacao from '../../../../components/elements/Paginacao';
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from '../../../../components/elements/Titulo';
import { GlobalContext } from '../../../context/GlobalContext';
import { IPaginatedList } from '../../../types/Generic/IPaginatedList';
import { ISuccessoMensagensResponse } from '../../../types/Generic/ISuccessoMensagensResponse';
import { sendDelete, sendPost } from "../../../utils/httpUtils";

import { ICapSuplier } from "../../../types/Cap/Suplier/ICapSuplier";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import { IDownloadFile } from "../../../types/Generic/IDownloadFile";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Supliers = () => {
  let navigate = useNavigate();

  const { selectedAccount, modules, addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [openExport, setOpenExport] = React.useState(false);
  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const [inputSearchValue, setInputSearchValue] = useState('');

  const [suplier, setSuplier] = useState<ICapSuplier[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFile, setSelectedFile] = useState<File>();

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>('p.NAME,asc');


  const iconStyles = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px' // Ajuste o espaçamento entre os ícones

  };
  const iconStylesDisabled = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px', // Ajuste o espaçamento entre os ícones
    opacity: 0.1

  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  async function consultaSuplier(page: number, sort?: string, suplierName?: string) {

    let url = `v1/cap/supliers/list?size=5&page=${page}${sort ? `&sort=${sort}` : ''}`

    const dados = { suplierName: suplierName }
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(url, dados, addMessages, setaLoading, navigate);
      setTotalPages(response.totalPages);
      setSuplier(response.content);
      setCurrentPage(page + 1);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }

  async function excluiSuplier(id: number) {

    let url = `v1/cap/supliers/${id}`
    const dados = {}
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(url, dados, addMessages, setaLoading, navigate);
      addMessages(response.messages);
      consultaSuplier(0, sort, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(debounce((value: string) => {
    setDebouncedSearchValue(value);
    // Aqui você pode chamar sua função de busca ou lógica com value
    if (jaFez) {
      consultaSuplier(currentPage - 1, sort, value);
    }
    jaFez = true;

    return
  }, 1000), []); // 1000 ms de delay


  useEffect(() => {
    consultaSuplier(0, sort);
    //console.log('modulesService.checkModules(["Asaas"], modules)');
    //console.log(modulesService.checkModules(["Asaas"], modules));
    //console.log(modules);
  }, []);

  useEffect(() => {

    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    consultaSuplier(page - 1, sort, debouncedSearchValue);
  };

  function handleClickEditar(suplier: ICapSuplier): void {
    navigate(`/area-logada/cap/edit-suplier/${suplier.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/cap/new-suplier`);
  }

  function handleChange(file: File) {
    setSelectedFile(file);
  }

  function formatarNomeArquivo(nomeArquivo: string) {
    // Removendo a extensão
    const nomeSemExtensao = nomeArquivo.split('.').slice(0, -1).join('.');
    const partes = nomeArquivo.split('.');
    const extensao = partes.length > 1 ? partes.pop() : '';

    // Limitando a 10 caracteres e adicionando '...'
    if (nomeArquivo.length > 27) {
      return nomeSemExtensao.substring(0, 16) + ' ... ' + nomeSemExtensao.substring(nomeSemExtensao.length - 3) + "." + extensao;
    } else {
      return nomeArquivo;
    }
  }

  const handleFileImport = async () => {
    if (selectedFile === undefined && !selectedFile) {
      alert("Favor selecionar um arquivo para enviar");
      return;
    }
    let url = `v1/cap/supliers/import/csv`
    const dados = new FormData();
    dados.append('file', selectedFile);

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const response = await sendPost<IDownloadFile>(url, dados, addMessages, setaLoading, navigate, headers);

      const byteCharacters = atob(response.fileBytes);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.fileType });
      const urlLink = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlLink;
      link.download = response.fileName;
      link.click();

      deselectFile();
      handleCloseImport();
      consultaSuplier(0, sort, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  function deselectFile() {
    setSelectedFile(undefined);
  }

  function handleClickExcluir(suplier: ICapSuplier): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir o fornecedor ${suplier.peopleName}`)) {
      excluiSuplier(suplier.id);
    }
    return
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    consultaSuplier(currentPage - 1, sortNovo, debouncedSearchValue);
    console.log(sortNovo)
  }

  const columns = [
    { displayName: 'Nome', columnName: 'p.NAME' },
    { displayName: 'Número do fornecedor', columnName: 'c.SUPLIER_NUMBER' },
    { displayName: 'Observações', columnName: 'c.OBSERVATIONS' },
    { displayName: 'Whatsapp', columnName: 'p.WHATSAPP' },
    { displayName: 'Email', columnName: 'p.EMAIL' },
    { displayName: 'CPF/CNPJ', columnName: 'p.CPF_CNPJ' },
    { displayName: 'Ações', columnName: null }
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Titulo>Fornecedores</Titulo>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <Button
                    color="inherit"
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    )}
                    onClick={handleOpenImport}
                  >
                    Importar
                  </Button>

                  <Modal
                    keepMounted
                    open={openImport}
                    onClose={handleCloseImport}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Importação de Fornecedores
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 2 }}
                      >
                        <Button component="label" variant="contained"
                          color="inherit"
                          startIcon={(
                            <SvgIcon fontSize="small">
                              <CloudArrowUpIcon />
                            </SvgIcon>
                          )}>
                          Selecionar arquivo
                          <VisuallyHiddenInput type="file" accept=".csv" onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              // Acessar o primeiro arquivo
                              const file = e.target.files[0];

                              // Verificar se o arquivo é um CSV
                              if (file.name.endsWith('.csv')) {
                                handleChange(file);
                              } else {
                                alert('Por favor, selecione um arquivo CSV.');
                              }
                            }
                          }} />
                        </Button>{selectedFile && (<>{formatarNomeArquivo(selectedFile.name)}</>)}

                        <Button component="label" variant="contained"
                          color="primary"
                          startIcon={(
                            <SvgIcon fontSize="small">
                              <ArrowRightIcon />
                            </SvgIcon>
                          )} onClick={handleFileImport}
                          sx={{ width: '195px' }}>
                          Importar do Arquivo
                        </Button>

                      </Stack>
                    </Box>
                  </Modal>
                </Stack>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <TextField
                  fullWidth
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <Button onClick={() => handleClickNovo()}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === 'desc' ? 'desc' : 'asc'}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {suplier.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>{row.peopleName}</CustomTableCell>
                      <CustomTableCell>{row.suplierNumber}</CustomTableCell>
                      <CustomTableCell>{row.observations}</CustomTableCell>
                      <CustomTableCell>{row.peoplePhone}</CustomTableCell>
                      <CustomTableCell>{row.peopleEmail}</CustomTableCell>
                      <CustomTableCell>{row.peopleCpfCnpj}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange} />
          </Stack>
        </Container>
      </Box>
    </React.Fragment >
  )
}

export default Supliers;