import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField, TextFieldProps } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IProductInvoice } from '../../../../types/Generic/IProductInvoice';
import { IProductInvoiceEditData } from '../../../../types/Generic/IProductInvoiceEditData';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EditInvoice.css';

const EditInvoice = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const invoiceId = Number(id);

    const [invoice, setInvoice] = useState<IProductInvoiceEditData>({id: invoiceId , invoiceType: '', invoiceSerie: 0, invoiceNumber: 0, invoiceDate: new Date(), invoiceValue: 0  } as IProductInvoiceEditData);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cre/invoice')
    }

    async function handleClickSalvar() {

        let url = `v1/cre/invoice/edit/${invoiceId}`
        const dados = invoice
        try {
            const response = await sendPost<IProductInvoice>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cre/invoice')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setInvoice(prevInvoice => ({ ...prevInvoice, [name]: valor }));
    };

    async function consultaInvoice() {

        let url = `v1/cre/invoice/${invoiceId}`
        const dados = {}
        try {
            const response = await sendGet<IProductInvoice>(url, dados, addMessages, setaLoading, navigate);
            setInvoice(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    useEffect(() => {
        consultaInvoice();
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Nota</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Serie" value={invoice.invoiceSerie} name='invoiceSerie' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Numero" value={invoice.invoiceNumber} name='invoiceNumber' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                            <DatePicker
                                    label="Data Nota"
                                    value={invoice.invoiceDate}
                                    onChange={(newValue: Date | null) => {
                                        setInvoice(prevInvoice => ({ ...prevInvoice, "invoiceDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='invoiceDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <TextField label="Valor" value={invoice.invoiceValue} name='invoiceValue' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EditInvoice