import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, SvgIcon, TextField, TextFieldProps } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { sendPost } from '../../../../utils/httpUtils';
import './InclusaoInvoice.css';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IProductInvoice } from '../../../../types/Generic/IProductInvoice';
import { IProductInvoiceNewData } from '../../../../types/Generic/IProductInvoiceNewData';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { DatePicker } from '@mui/x-date-pickers';

const InclusaoInvoice = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const [invoice, setInvoice] = useState<IProductInvoiceNewData>({ clientId: 0, invoiceType: 'S', invoiceSerie: 0, invoiceNumber: 0, invoiceDate: new Date(), invoiceValue: 0 } as IProductInvoiceNewData);

    const [clientsList, setClientsList] = useState<ICreClient[]>([] as ICreClient[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cre/invoice')
    }

    async function handleClickSalvar() {

        let url = `v1/cre/invoice/new`
        const dados = {
            clientId: invoice.clientId,
            invoiceType: invoice.invoiceType,
            invoiceSerie: invoice.invoiceSerie,
            invoiceNumber: invoice.invoiceNumber,
            invoiceDate: invoice.invoiceDate,
            invoiceValue: invoice.invoiceValue
        }
        console.log(dados)
        try {
            const response = await sendPost<IProductInvoice>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cre/invoice')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }


    async function consultaClients(clientName: string) {

        let url = `v1/client/list?size=10&page=0&sort=NAME,asc`;
        const dados = { clientName: clientName }
        try {
            const response = await sendPost<IPaginatedList<ICreClient>>(url, dados, addMessages, setaLoading, navigate);
            setClientsList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setInvoice(prevInvoice => ({ ...prevInvoice, [name]: valor }));
    };

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Notas</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clientsList}
                                    getOptionLabel={(option) => option.peopleName} // Use o nome do cliente como label
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Nome" name="clientId" />}
                                    onInputChange={(event, newInputValue) => {
                                        consultaClients(newInputValue);
                                    }}
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            setInvoice(prevInvoice => ({ ...prevInvoice, clientId: value.id }));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Serie" value={invoice.invoiceSerie} name='invoiceSerie' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Numero" value={invoice.invoiceNumber} name='invoiceNumber' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Data Nota"
                                    value={invoice.invoiceDate}
                                    onChange={(newValue: Date | null) => {
                                        setInvoice(prevInvoice => ({ ...prevInvoice, "invoiceDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='invoiceDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Valor" value={invoice.invoiceValue} name='invoiceValue' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Tipo da Nota</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={invoice.invoiceType}
                                        name="invoiceType"
                                        value={invoice.invoiceType}
                                        onChange={
                                            (event) => {
                                                setInvoice(prevInvoice => ({ ...prevInvoice, invoiceType: event.target.value }));
                                            }
                                        }
                                    >
                                        <FormControlLabel value="S" control={<Radio />} label="Saída" />
                                        <FormControlLabel value="E" control={<Radio />} label="Entrada" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default InclusaoInvoice