import React, { useContext, useEffect, useRef, useState } from "react";

import "./Config.css";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import Titulo from "../../../../components/elements/Titulo";
import WppQrcode from "../../../../components/elements/WppQrcode";
import { GlobalContext } from "../../../context/GlobalContext";
import { ICreConfig } from "../../../types/Cre/Config/ICreConfig";
import { IResponse } from "../../../types/Generic/IResponse";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { sendGet, sendPost } from "../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Config = () => {
  const { configWpp, loadConfigWpp, addMessage, addMessages } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [wppDefaultMessage, setWppDefaultMessage] = useState("");
  const [wppBillMessage, setWppBillMessage] = useState("");
  const [defaultSendPermitted, setDefaultSendPermitted] = useState(false);

  const textareaWppDefaultMessageRef = useRef<HTMLTextAreaElement>(null);
  const textareaWppBillMessageRef = useRef<HTMLTextAreaElement>(null);

  const valorInicialConfigCre: ICreConfig = {
    id: 0,
    accountId: 0,
    accountName: "",
    wppDefaultMessage: "",
    wppBillMessage: "",
    createDate: "",
    updateDate: "",
    userCreateId: 0,
    userUpdateId: 0,
    userCreateName: "",
    userUpdateName: "",
  };
  const [configCre, setConfigCre] = useState<ICreConfig>(valorInicialConfigCre);

  const loadConfigCre = async () => {
    let url = `v1/config/cre`;
    const dados = {};
    try {
      const response = await sendGet<ICreConfig>(url, dados, addMessages, setaLoading, navigate);
      setConfigCre(response);
      setWppDefaultMessage(response.wppDefaultMessage);
      setWppBillMessage(response.wppBillMessage);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const Textarea = styled("textarea")(
    ({ theme }) => `
    width: 700px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    height: 120px;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const { setaLoading } = useContext(GlobalContext);

  async function desconectaWppConfig(wppConfigId: number) {
    let url = `v1/config/wpp/disconnect/${wppConfigId}`;
    const dados = {};
    try {
      const response = await sendGet<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      addMessages(response.messages);
      loadConfigWpp();
      setaLoading(false);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    loadConfigWpp();
    loadConfigCre();
    if (configWpp) {
      setValue(0);
    } else {
      setValue(1);
    }
  }, []);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDefaultSendPermitted(checked);
    setaLoading(true);
    let url = `v1/config/wpp/update-default-send-permitted/${checked ? 1 : 0}`;
    const dados = {};
    try {
      const response = await sendPost<IResponse<ISuccessoMensagensResponse>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      loadConfigWpp();
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    } finally {
      setaLoading(false);
    }
  };

  function handleClickDesconectar(
    wppConfigId: number
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    desconectaWppConfig(wppConfigId);
    loadConfigWpp();
    return;
  }

  async function handleClickAtualizarCreConfig(id: number): Promise<void> {
    let url = `v1/config/cre/update`;
    const dados = {
      wppDefaultMessage: textareaWppDefaultMessageRef.current?.value,
      wppBillMessage: textareaWppBillMessageRef.current?.value,
    };
    try {
      const response = await sendPost<ICreConfig>(url, dados, addMessages, setaLoading, navigate);
      const retornoDados = response;
      setConfigCre(retornoDados);
      setWppDefaultMessage(retornoDados.wppDefaultMessage);
      setWppBillMessage(retornoDados.wppBillMessage);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack spacing={1}>
                <Titulo>Configurações</Titulo>
              </Stack>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="Configurações">
                {configWpp && <Tab label="WhatsApp" {...a11yProps(0)} />}
                {configCre && <Tab label="Contas à Receber" {...a11yProps(1)} />}
              </Tabs>
            </Box>
            {configWpp && (
              <CustomTabPanel value={value} index={0}>
                <Card sx={{ minWidth: 365, maxWidth: 365 }}>
                  <CardContent sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Conta:</b> {configWpp.accountName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Instância:</b> {configWpp.instanceId}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Status:</b> {configWpp.instanceStatus}
                    </Typography>
                    {configWpp.instanceStatus === "Conectada" && (
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Telefone Conectado:</b> {configWpp.whatsapp}
                      </Typography>
                    )}
                    {configWpp.instanceStatus === "Desconectada" && (
                      <WppQrcode wppConfigId={configWpp.id} conectado={false} />
                    )}
                  </CardContent>
                  <CardActions sx={{ padding: "4px" }}>
                    {configWpp.instanceStatus === "Conectada" && (
                      <Button size="small" onClick={() => handleClickDesconectar(configWpp.id)}>
                        <b>Desconectar</b>
                      </Button>
                    )}
                  </CardActions>
                </Card>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configWpp.defaultSendPermitted || false}
                          onChange={handleChange}
                          name="defaultSendPermitted"
                          value={defaultSendPermitted || false}
                        />
                      }
                      label="Envio Permitido Padrão"
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            )}
            {configCre && (
              <CustomTabPanel value={value} index={1}>
                <Card sx={{ minWidth: 660, maxWidth: 800 }}>
                  <CardContent sx={{ alignContent: "initial" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Conta:</b> {configCre.accountName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Mensagem Padrão WhatsApp:</b>
                      <br />
                      <Textarea
                        ref={textareaWppDefaultMessageRef}
                        defaultValue={wppDefaultMessage}
                      />
                      <b>Mensagem Faturas WhatsApp:</b>
                      <br />
                      <Textarea ref={textareaWppBillMessageRef} defaultValue={wppBillMessage} />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => handleClickAtualizarCreConfig(configCre.id)}
                    >
                      Atualizar
                    </Button>
                  </CardActions>
                </Card>
              </CustomTabPanel>
            )}
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Config;
