import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.png";

const Logo = () => {
  return (
    <Link to="/area-logada/dashboard">
      <img src={logo} alt="Logo do Sistema BizMaster" style={{ width: "150px" }} />
    </Link>
  );
};

export default Logo;
