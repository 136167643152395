import { Autocomplete, Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, SvgIcon, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { IMaskInput } from 'react-imask';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';



import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICep } from '../../../../types/Generic/ICep';
import { ICapSuplier } from '../../../../types/Cap/Suplier/ICapSuplier';
import { ICapSuplierNewData } from '../../../../types/Cap/Suplier/ICapSuplierNewData';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { sendPost, sendSimpleGet } from '../../../../utils/httpUtils';
import './NewSuplier.css';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function CepMaskCustom(props, ref): React.ReactElement {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00000-000"
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function PhoneMaskCustom(props, ref): React.ReactElement {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={[
                    { mask: '+55 (00) 0000-0000' },
                    { mask: '+55 (00) 00000-0000' },
                  ]}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const MobileMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function MobileMaskCustom(props, ref): React.ReactElement {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={[
                    { mask: '+55 (00) 0000-0000' },
                    { mask: '+55 (00) 00000-0000' },
                  ]}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function CpfMaskCustom(props, ref): React.ReactElement {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function CnpjMaskCustom(props, ref): React.ReactElement {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00.000.000/000#-00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const NewSuplier = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    //Inicia todas as propriedadese do objeto como string vazia
    const [suplier, setSuplier] = useState<ICapSuplierNewData>({
        peopleName: '',
        peopleCpfCnpj: '',
        peopleFantasyName: '',
        peopleEmail: '',
        peoplePhone: '',
        peopleMobilePhone: '',
        addressStreet: '',
        addressNumber: '',
        addressComplement: '',
        addressNeighborhood: '',
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
        addressRegion: '',
        municipalInscription: '',
        stateInscription: '',
        observations: '',
        peopleInstagram: '',
        suplierNumber: '',
        peopleAdditionalEmails: ''
    } as ICapSuplierNewData);

    const [peopleType, setPeopleType] = useState('F');

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cap/supliers')
    }

    function validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validateCpf(cpf: string) {

        if (cpf.length !== 11) {
            return false;
        }

        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cpf.substring(9, 10))) {
            return false;
        }

        sum = 0;

        for (let i = 1; i <= 10; i++) {
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cpf.substring(10, 11))) {
            return false;
        }

        return true;
    }

    function validateCnpj(cnpj: string) {

        if (cnpj === '' || cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (
            cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999'
        ) {
            return false;
        }

        // Valida DVs
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        const digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += Number(numbers.charAt(size - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
            return false;
        }

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += Number(numbers.charAt(size - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
            return false;
        }

        return true;
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        suplier.peopleName = suplier.peopleName.trim();
        suplier.peopleCpfCnpj = suplier.peopleCpfCnpj.trim();
        suplier.addressPostalCode = suplier.addressPostalCode.trim();
        suplier.addressNumber = suplier.addressNumber.trim();
        suplier.peopleEmail = suplier.peopleEmail.trim();
        suplier.peoplePhone = suplier.peoplePhone.trim();
        suplier.peopleMobilePhone = suplier.peopleMobilePhone.trim();
        suplier.peopleInstagram = suplier.peopleInstagram.trim();
        suplier.suplierNumber = suplier.suplierNumber.trim();
        suplier.peopleAdditionalEmails = suplier.peopleAdditionalEmails.trim();
        suplier.observations = suplier.observations.trim();
        if (peopleType === 'F') {
            suplier.peopleFantasyName = suplier.peopleFantasyName.trim();
            suplier.municipalInscription = suplier.municipalInscription.trim();
            suplier.stateInscription = suplier.stateInscription.trim();
        }
        else {
            suplier.peopleFantasyName = '';
            suplier.municipalInscription = '';
            suplier.stateInscription = '';
        }
        //Retirar caracteres não numéricos dos campos de cpf/cnpj, telefone e celular
        suplier.peopleCpfCnpj = suplier.peopleCpfCnpj.replace(/\D/g, '');
        suplier.peoplePhone = suplier.peoplePhone.replace(/\D/g, '');
        suplier.suplierNumber = suplier.suplierNumber.replace(/\D/g, '');
        suplier.peopleMobilePhone = suplier.peopleMobilePhone.replace(/\D/g, '');
        suplier.addressPostalCode = suplier.addressPostalCode.replace(/\D/g, '');

        if (suplier.peopleName === '') {
            addMessages(['Nome é obrigatório']);
            return false;
        }
        if (suplier.peopleCpfCnpj === '') {
            addMessages(['CPF/CNPJ é obrigatório']);
            return false;
        } else {
            if (peopleType === 'F') {
                if (!validateCpf(suplier.peopleCpfCnpj)) {
                    addMessages(['CPF inválido']);
                    return false;
                }
            } else {
                if (!validateCnpj(suplier.peopleCpfCnpj)) {
                    addMessages(['CNPJ inválido']);
                    return false;
                }
            }
        }
        if (suplier.addressPostalCode === '') {
            addMessages(['CEP do endereço é obrigatório']);
            return false;
        }
        if (suplier.addressNumber === '') {
            addMessages(['Número do endereço é obrigatório']);
            return false;
        }

        if (suplier.peopleEmail !== '') {
            if (!validateEmail(suplier.peopleEmail)) {
                addMessages(['Email inválido']);
                return false;
            }
        }
        if (suplier.peoplePhone !== '') {
            if (suplier.peoplePhone.length < 10) {
                addMessages(['Telefone inválido']);
                return false;
            }
        }
        if (suplier.peopleMobilePhone !== '') {
            if (suplier.peopleMobilePhone.length < 11) {
                addMessages(['Celular inválido']);
                return false;
            }
        }
        return true;
    }
    async function handleClickSalvar() {
        if (!validateForm()) {
            return;
        }

        let url = `v1/cap/supliers/new`
        /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
        const dados = { name: suplier.peopleName, cpfCnpj: suplier.peopleCpfCnpj, number: suplier.number, suplierNumber: suplier.suplierNumber, fantasyName: suplier.peopleFantasyName, email: suplier.peopleEmail, phone: suplier.peoplePhone, mobilePhone: suplier.peopleMobilePhone, address: suplier.addressStreet, addressNumber: suplier.addressNumber, complement: suplier.addressComplement, province: suplier.addressNeighborhood, city: suplier.addressCity, state: suplier.addressState, postalCode: suplier.addressPostalCode, region: suplier.addressRegion, municipalInscription: suplier.municipalInscription, stateInscription: suplier.stateInscription, observations: suplier.observations, instagram: suplier.peopleInstagram, additionalEmails: suplier.peopleAdditionalEmails }
        //console.log(dados)
        try {
            const response = await sendPost<ICapSuplier>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cap/supliers')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setSuplier(prevSuplier => ({ ...prevSuplier, [name]: valor }));
        if (name === 'addressPostalCode' && valor.length === 9) {
            fetchCep(valor);
        }
    };
    /*
    logradouro: string
    complemento: string
    bairro: string
    localidade: string
    uf: string
    ibge: string
    gia: string
    ddd: string
    siafi: string
    */
    async function fetchCep(cep: string) {
        let url = `https://viacep.com.br/ws/${cep}/json/`
        const dados = {}
        //console.log(dados)
        try {
            const response = await sendSimpleGet<ICep>(url, dados, addMessages, setaLoading, navigate);
            console.log(response);
            setSuplier(prevSuplier => ({ ...prevSuplier, addressStreet: response.logradouro, addressNeighborhood: response.bairro, addressCity: response.localidade, addressState: response.uf }));
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }


    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Fornecedor</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>    
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-people-type-label"
                                        defaultValue={peopleType}
                                        name="peopleType"
                                        onChange={
                                            (event) => {
                                                setPeopleType(event.target.value);
                                                if (event.target.value === 'F') {
                                                    setSuplier(prevSuplier => ({ ...prevSuplier, peopleFantasyName: '', municipalInscription: '', stateInscription: '' }));
                                                }
                                            }
                                        }
                                    >
                                        <FormControlLabel value="F" control={<Radio />} label="Física" />
                                        <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={peopleType === 'F' ? 'Nome' : 'Razão Social'}
                                    value={suplier.peopleName}
                                    name='peopleName'
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }} />
                            </Grid>

                            {peopleType === 'J' ? (
                                <Grid item xs={12} >
                                    <TextField
                                        label="Nome Fantasia"
                                        value={suplier.peopleFantasyName}
                                        name='peopleFantasyName'
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>) : ''}
                            <Grid item xs={12}>
                                <TextField
                                    label="CPF/CNPJ"
                                    value={suplier.peopleCpfCnpj}
                                    name='peopleCpfCnpj'
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={peopleType === 'F' ? { inputComponent: CpfMaskCustom as any } : { inputComponent: CnpjMaskCustom as any }} />
                            </Grid>
                            {peopleType === 'J' ? (
                                <>
                                    <Grid item xs={12}>
                                        <TextField label="Inscrição Municipal" value={suplier.municipalInscription} name='municipalInscription' onChange={handleChange} variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Inscrição Estadual" value={suplier.stateInscription} name='stateInscription' onChange={handleChange} variant="outlined" fullWidth />
                                    </Grid></>) : ''}
                            <Grid item xs={12}>
                                <TextField label="Email" value={suplier.peopleEmail} name='peopleEmail' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="CEP"
                                    value={suplier.addressPostalCode}
                                    name='addressPostalCode'
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ inputComponent: CepMaskCustom as any }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Rua" value={suplier.addressStreet} name='addressStreet' onChange={handleChange} variant="outlined" fullWidth disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Numero" value={suplier.addressNumber} name='addressNumber' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Complemento" value={suplier.addressComplement} name='addressComplement' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Bairro" value={suplier.addressNeighborhood} name='addressNeighborhood' onChange={handleChange} variant="outlined" fullWidth disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Cidade" value={suplier.addressCity} name='addressCity' onChange={handleChange} variant="outlined" fullWidth disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Estado" value={suplier.addressState} name='addressState' onChange={handleChange} variant="outlined" fullWidth disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Região" value={suplier.addressRegion} name='addressRegion' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Telefone"
                                    value={suplier.peoplePhone}
                                    name='peoplePhone'
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ inputComponent: PhoneMaskCustom as any }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Celular"
                                    value={suplier.peopleMobilePhone}
                                    name='peopleMobilePhone'
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ inputComponent: MobileMaskCustom as any }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Numero do Fornecedor" value={suplier.suplierNumber} name='suplierNumber' onChange={handleChange}
                                    InputProps={{ inputComponent: PhoneMaskCustom as any }}
                                    variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Emails Adicionais" value={suplier.peopleAdditionalEmails} name='peopleAdditionalEmails' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Observações" value={suplier.observations} name='observations' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewSuplier;


