
import React, { useCallback, useContext, useEffect, useState } from "react";

import './Payments.css';

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';


import PlusIcon from '@heroicons/react/24/solid/PlusIcon';

import { Box, Button, Container, IconButton, Modal, Paper, Stack, SvgIcon, Table, TableBody, TableContainer, TableRow, TextField, TextFieldProps, Tooltip, Typography, styled } from "@mui/material";
import { subDays } from "date-fns";
import debounce from 'lodash/debounce';
import { useNavigate } from "react-router-dom";
import { ReactComponent as AsaasIcon } from '../../../../assets/img/asaas.svg';
import CustomTableCell from '../../../../components/elements/CustomTableCell';
import Paginacao from '../../../../components/elements/Paginacao';
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from '../../../../components/elements/Titulo';
import { GlobalContext } from '../../../context/GlobalContext';
import { ICapPayment } from "../../../types/Cap/Payment/ICapPayment";
import { IPaginatedList } from '../../../types/Generic/IPaginatedList';
import { ISuccessoMensagensResponse } from '../../../types/Generic/ISuccessoMensagensResponse';
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import { DatePicker } from "@mui/x-date-pickers";
import { formataDateLocal } from "../../../utils/date-utils";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Payment = () => {
  let navigate = useNavigate();

  const { selectedAccount, addMessages, setaLoading } = useContext(GlobalContext);

  const initialDays = 30;

  const [initialDate, setInitialDate] = useState<Date>(subDays(new Date(), initialDays));
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [openExport, setOpenExport] = React.useState(false);
  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const [inputSearchValue, setInputSearchValue] = useState('');

  const [payment, setPayment] = useState<ICapPayment[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>('PAYMENT_VALUE,asc');


  const iconStyles = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px' // Ajuste o espaçamento entre os ícones

  };
  const iconStylesDisabled = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px', // Ajuste o espaçamento entre os ícones
    opacity: 0.1

  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  async function searchPayment(page: number, sort?: string, initialDate?: Date, finalDate?: Date, searchQuery?: string) {


    let url = `v1/cap/payment/list?size=5&page=${page}${sort ? `&sort=${sort}` : ''}`
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: searchQuery
    }
    try {
      const response = await sendPost<IPaginatedList<ICapPayment>>(url, dados, addMessages, setaLoading, navigate);
      console.log("response:", response);
      setTotalPages(response.totalPages);
      setPayment(response.content);
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }

  async function deletePayment(id: number) {

    let url = `/v1/cap/payment/${id}`
    const dados = {}
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(url, dados, addMessages, setaLoading, navigate);
      addMessages(response.messages);
      searchPayment(0, sort, initialDate, finalDate, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(debounce((value: string) => {
    setDebouncedSearchValue(value);
    // Aqui você pode chamar sua função de busca ou lógica com value
    if (jaFez) {
      searchPayment(currentPage - 1, sort, initialDate, finalDate, value);
    }
    jaFez = true;

    return
  }, 1000), []); // 1000 ms de delay


  useEffect(() => {
    searchPayment(0, sort, initialDate, finalDate);
  }, []);

  useEffect(() => {

    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchPayment(page - 1, sort, initialDate, finalDate, debouncedSearchValue);
  };

  function handleClickEdit(payment: ICapPayment): void {
    navigate(`/area-logada/cap/edit-payment/${payment.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/cap/new-payment`);
  }

  function handleClickDelete(payment: ICapPayment): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir o pagamento ${payment.paymentValue}`)) {
      deletePayment(payment.id);
    }
    return
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchPayment(currentPage - 1, sortNovo, initialDate, finalDate, debouncedSearchValue);
  }

  const columns = [
    { displayName: 'Id', columnName: 'ID' },
    { displayName: 'Valor do pagamento', columnName: 'PAYMENT_VALUE' },
    { displayName: 'Data do pagamento', columnName: 'PAYMENT_DATE' },
    { displayName: 'Total', columnName: 'IS_TOTAL' },
    { displayName: 'Usuário', columnName: 'USER_UPDATE_ID' },
    { displayName: 'Data Criação', columnName: 'CREATE_DATE' },
    { displayName: 'Usuário Atualização', columnName: 'USER_UPDATE_NAME' },
    { displayName: 'Data Atualização', columnName: 'UPDATE_DATE' },
    { displayName: 'Ações', columnName: null }
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack spacing={1}>
                <Titulo>Pagamentos</Titulo>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >

                </Stack>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <TextField
                  fullWidth
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <DatePicker
                  label="Data inicial"
                  value={initialDate}
                  onChange={(newValue: Date | null) => {
                    setInitialDate(newValue ? newValue : subDays(new Date(), initialDays));
                    searchPayment(0, sort, newValue ? newValue : subDays(new Date(), initialDays), finalDate, debouncedSearchValue);
                  }}
                  renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} />}
                />
                <DatePicker
                  label="Data final"
                  value={finalDate}
                  onChange={(newValue: Date | null) => {
                    setFinalDate(newValue ? newValue : new Date());
                    searchPayment(0, sort, initialDate, newValue ? newValue : new Date(), debouncedSearchValue);
                  }}
                  renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} />}
                />
                <Button onClick={() => handleClickNovo()}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === 'desc' ? 'desc' : 'asc'}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <TableBody>
                  {payment.map((row) => (
                    <TableRow key={row.id}>
                      <CustomTableCell><Box display="flex" alignItems="center">{row.paymentValue}</Box></CustomTableCell>
                      <CustomTableCell><Box display="flex" alignItems="center">{formataDateLocal(row.paymentDate)}</Box></CustomTableCell>
                      <CustomTableCell><Box display="flex" alignItems="center">{row.isTotal}</Box></CustomTableCell>
                      <CustomTableCell>
                        <Box display="flex" alignItems="center">
                          <EditAcctionButton row={row} handleClickEditar={handleClickEdit} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickDelete} />
                        </Box>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange} />
          </Stack>
        </Container>
      </Box>
    </React.Fragment >
  )
}

export default Payment