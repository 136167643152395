import * as React from 'react';
import Typography from '@mui/material/Typography';

interface TituloProps {
  children?: React.ReactNode;
}

export default function Subtitulo(props: TituloProps) {
  return (
    <Typography component="h3" variant="h5" color="secondary" gutterBottom align='left'>
      {props.children}
    </Typography>
  );
}