import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IWallet } from '../../../../types/Cre/Wallet/IWallet';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EdicaoWallet.css';
import { IWalletEditData } from '../../../../types/Cre/Wallet/IWalletEditData';

const EdicaoWallet = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const walletId = Number(id);

    const [wallet, setWallet] = useState<IWalletEditData>({ id: walletId, walletName: '', walletNumber: '' } as IWalletEditData);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/wallet')
    }

    async function handleClickSalvar() {

        let url = `v1/wallet/edit/${walletId}`
        const dados = wallet
        try {
            const response = await sendPost<IWallet>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/wallet')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setWallet(prevWallet => ({ ...prevWallet, [name]: valor }));
    };

    async function consultaWallet() {

        let url = `v1/wallet/${walletId}`
        const dados = {}
        try {
            const response = await sendGet<IWallet>(url, dados, addMessages, setaLoading, navigate);
            setWallet(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    useEffect(() => {
        consultaWallet();
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Carteira</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Nome" value={wallet.walletName} name='walletName' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Numero" value={wallet.walletNumber} name='walletNumber' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EdicaoWallet