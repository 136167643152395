import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import Bars4Icon from "@heroicons/react/24/solid/Bars4Icon";
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ChatBubbleOvalLeftEllipsisIcon from "@heroicons/react/24/solid/ChatBubbleOvalLeftEllipsisIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import DocumentCheckIcon from "@heroicons/react/24/solid/DocumentCheckIcon";
import DocumentIcon from "@heroicons/react/24/solid/DocumentIcon";
import RectangleGroupIcon from "@heroicons/react/24/solid/RectangleGroupIcon";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import WalletIcon from "@heroicons/react/24/solid/WalletIcon";
import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import PuzzlePiece from "@heroicons/react/24/solid/PuzzlePieceIcon";
import WrenchScrewdriverIcon from "@heroicons/react/24/solid/WrenchScrewdriverIcon";
import BanknotesIcon from "@heroicons/react/24/solid/BanknotesIcon";
import ArrowDownTrayIcon from "@heroicons/react/24/solid/ArrowDownTrayIcon"; // Substitua "Other" pelo nome real do ícone
import ArrowUpTrayIcon from '@heroicons/react/24/solid/ArrowUpTrayIcon';
import CreditCardIcon from '@heroicons/react/24/solid/CreditCardIcon';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';

import WhatsAppIcon from "@mui/icons-material/WhatsApp";


import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Typography,
  styled,
  useMediaQuery,
  alpha,
  Collapse,
  ButtonBase,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../core/context/GlobalContext";
import { createTheme } from "../../../core/theme/main";
import { ILogin } from "../../../core/types/Generic/ILogin";
import { IToken } from "../../../core/types/Generic/IToken";
import { IUserData } from "../../../core/types/Generic/IUserData";
import { sendGet, sendPost } from "../../../core/utils/httpUtils";
import BotaoNavegacao from "../../elements/BotaoNavegacao";
import Logo from "../../elements/Logo";
import { Scrollbar } from "../../elements/Scrollbar";
import { animated, useSpring } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";
import { Link } from "react-router-dom";
import { height } from "@mui/system";

const BarraLateral = () => {
  interface Item {
    id: number;
    title: string;
    path: string;
    icon: React.ElementType;
    iconSvg: JSX.Element;
    children?: Item[];
  }

  const [menus, setMenus] = React.useState<Item[]>([]);

  let navigate = useNavigate();

  const {
    selectedAccount,
    changeSelectedAccount,
    roles,
    accounts,
    changeAccounts,
    changeRoles,
    changeModules,
    addMessages,
    setaLoading,
  } = useContext(GlobalContext);

  const efetuarLogout = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  };

  const iconMap: { [key: string]: React.ElementType } = {
    ChartBarIcon: ChartBarIcon,
    DocumentCheckIcon: DocumentCheckIcon,
    ChatBubbleOvalLeftEllipsisIcon: ChatBubbleOvalLeftEllipsisIcon,
    UsersIcon: UsersIcon,
    CogIcon: CogIcon,
    Bars4Icon: Bars4Icon,
    RectangleGroupIcon: RectangleGroupIcon,
    UserIcon: UserIcon,
    UserCircleIcon: UserCircleIcon,
    DocumentIcon: DocumentIcon,
    CurrencyDollarIcon: CurrencyDollarIcon,
    WalletIcon: WalletIcon,
    DocumentTextIcon: DocumentTextIcon,
    PuzzlePiece: PuzzlePiece,
    WrenchScrewdriverIcon: WrenchScrewdriverIcon,
    BanknotesIcon: BanknotesIcon,
    ArrowDownTrayIcon: ArrowDownTrayIcon,
    WhatsAppIcon: WhatsAppIcon,
    CreditCardIcon: CreditCardIcon,
    RequestQuoteIcon: RequestQuoteIcon,
    ArrowUpTrayIcon: ArrowUpTrayIcon, 
    AccountBalanceIcon: AccountBalanceIcon,
    SavingsIcon: SavingsIcon,
    //Other: OtherIcon, // Substitua "Other" pelo nome real do ícone
    // Adicione mais mapeamentos conforme necessário
  };

  async function loadUserData() {
    let url = `v1/users/data`;
    const dados = {};
    try {
      const response = await sendGet<IUserData>(url, dados, addMessages, setaLoading, navigate);
      changeAccounts(response.accounts);
      changeRoles(response.roles);

      const items = response.menus.map((menu) => {
        const IconComponent = iconMap[menu.menuIcon];

        if (!IconComponent) {
          throw new Error(`Ícone desconhecido: ${menu.menuIcon}`);
        }

        return {
          id: menu.id,
          title: menu.menuTitle,
          path: menu.menuPath,
          icon: iconMap[menu.menuIcon],
          iconSvg: (
            <SvgIcon fontSize="small">
              <IconComponent />
            </SvgIcon>
          ),
          children: menu.children?.map((submenu) => {
            const IconComponent = iconMap[submenu.menuIcon];

            if (!IconComponent) {
              throw new Error(`Ícone desconhecido: ${submenu.menuIcon}`);
            }

            return {
              id: submenu.id,
              title: submenu.menuTitle,
              path: submenu.menuPath,
              icon: iconMap[menu.menuIcon],
              iconSvg: (
                <SvgIcon fontSize="small">
                  <IconComponent />
                </SvgIcon>
              ),
            };
          }),
        };
      });
      setMenus(items);

      const currentAccount = sessionStorage.getItem("currentAccount");
      if (currentAccount) {
        changeSelectedAccount(currentAccount);
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = async (event: SelectChangeEvent) => {
    console.log("handleChange: " + event.target.value);

    let url = `v1/users/change-account`;
    const dados = { accountId: event.target.value };
    try {
      const response = await sendPost<ILogin>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      const token = response.token;
      const decoded = jwtDecode<IToken>(token);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("currentAccount", decoded.accountId);
      changeSelectedAccount(decoded.accountId as string);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      efetuarLogout();
    }

    loadUserData();
    navigate("/area-logada/dashboard");
  };

  useEffect(() => {
    loadUserData();
  }, []);

  type StyledTreeItemProps = Omit<TreeItemProps, "label"> & {
    labelIcon: React.ElementType;
    iconSvg: JSX.Element;
    labelText: string;
    active: boolean;
    disabled: boolean;
    external: boolean;
    path: string;
  };
  const StyledTreeItemLabel = styled(Typography)({
    color: "inherit",
    fontFamily: "General Sans",
    fontWeight: "inherit",
    flexGrow: 1,
  }) as unknown as typeof Typography;

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[400],
    position: "relative",
    [`& .${treeItemClasses.content}`]: {
      flexDirection: "row-reverse",
      borderRadius: theme.spacing(0.7),
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      fontWeight: 500,
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        marginRight: theme.spacing(3),
      },
      [`&.Mui-expanded `]: {
        "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.primary.dark
              : theme.palette.secondary.dark,
        },
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          left: "50px",
          top: "10px",
          height: "calc(100% - 48px)",
          width: "0.5px",
        },
      },
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.mode === "light" ? theme.palette.primary.light : "black",
      },
      [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : theme.palette.secondary.dark,
        color: "black",
      },
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: theme.spacing(1),
      [`& .${treeItemClasses.content}`]: {
        fontWeight: 500,
      },
    },
  })) as unknown as typeof TreeItem;

  const AnimatedCollapse = animated(Collapse);

  function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
      to: {
        opacity: props.in ? 1 : 0,
        transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
      },
    });

    return <AnimatedCollapse style={style} {...props} />;
  }

  const StyledTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>
  ) {
    const {
      labelIcon: LabelIcon,
      iconSvg,
      labelText,
      active,
      disabled,
      external,
      path,
      ...other
    } = props;
    const linkProps = path
      ? external
        ? {
          component: "a",
          href: path,
          target: "_blank",
        }
        : {
          component: Link,
          to: path,
        }
      : {};
    return path ? (
      <Link to={path} style={{ textDecoration: "none" }}>
        <StyledTreeItemRoot
          slots={{
            groupTransition: TransitionComponent,
          }}
          label={
            <BotaoNavegacao
              active={active}
              disabled={disabled}
              external={external}
              icon={iconSvg}
              path={path}
              title={labelText}
            />
          }
          {...other}
          ref={ref}
          {...linkProps}
        />
      </Link>
    ) : (
      <StyledTreeItemRoot
        slots={{
          groupTransition: TransitionComponent,
        }}
        label={
          <BotaoNavegacao
            active={active}
            disabled={disabled}
            external={external}
            icon={iconSvg}
            path={path}
            title={labelText}
          />
        }
        {...other}
        ref={ref}
        {...linkProps}
      />
    );
  });
  const temVariasContas = true;
  const mainListItems = (
    <Box>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            display: "inline-flex",
            height: 32,
            width: 32,
          }}
        >
          <Logo />
        </Box>
        {accounts && accounts.length > 1 && (
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.04)",
              borderRadius: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <FormControl id="form-select-conta" fullWidth>
              <InputLabel id="conta-simple-select-label">Conta</InputLabel>
              <Select
                sx={{
                  color: "white",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "inherit", // Mantém a borda inalterada no foco
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // Estiliza a borda padrão
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      // Estiliza a borda no hover
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      // Estiliza a borda no foco
                      borderColor: "white",
                    },
                  },
                  height: "35px",
                }}
                labelId="conta-simple-select-label"
                id="conta-simple-select"
                value={selectedAccount}
                defaultValue={selectedAccount}
                label="Conta"
                onChange={handleChange}
              >
                {accounts.map((row) => (
                  <MenuItem key={row.accountId} value={row.accountId} sx={{ height: "30px" }}>
                    {row.accountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 1,
          py: 1,
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          <SimpleTreeView
            id="menuPrincipal"
            aria-label="menuPrincipal"
            sx={{ height: "fit-content", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            {menus.map((item) => {
              const active = item.path ? location.pathname === item.path : false;
              return (
                <StyledTreeItem
                  itemId={item.id.toString()}
                  labelText={item.title}
                  labelIcon={item.icon}
                  iconSvg={item.iconSvg}
                  active={active}
                  disabled={false}
                  external={false}
                  path={item.path}
                >
                  {item.children?.map((child) => {
                    return (
                      <StyledTreeItem
                        itemId={child.id.toString()}
                        labelText={child.title}
                        labelIcon={child.icon}
                        iconSvg={child.iconSvg}
                        active={active}
                        disabled={false}
                        external={false}
                        path={child.path}
                      />
                    );
                  })}
                </StyledTreeItem>
              );
            })}
          </SimpleTreeView>
          {/*menus?.map((item) => {
                            const active = item.path ? (location.pathname === item.path) : false;

                            return (
                                <BotaoNavegacao
                                    active={active}
                                    disabled={false}
                                    external={false}
                                    icon={item.icon}
                                    key={item.title}
                                    path={item.path}
                                    title={item.title}
                                >
                                    {item.children?.map((child) => {
                                        return (
                                            <BotaoNavegacao
                                                active={child.path ? (location.pathname === child.path) : false}
                                                disabled={false}
                                                external={false}
                                                icon={child.icon}
                                                key={child.title}
                                                path={child.path}
                                                title={child.title}
                                            />
                                        );
                                    })}
                                </BotaoNavegacao>
                            );
                        })*/}
        </Stack>
      </Box>
    </Box>
  );

  const drawerWidth = 240;

  const theme = createTheme();

  const matches = useMediaQuery(theme.breakpoints.up("xl")); // 'lg' corresponde a 1280px ou mais

  const [open, setOpen] = useState(matches);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <SvgIcon
        fontSize="large"
        color="inherit"
        sx={{ padding: "16px" }}
        onClick={handleDrawerToggle}
      >
        <Bars3Icon />
      </SvgIcon>
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
            height: "100%",
            overflow: "auto",
          },
        }}
        variant={matches ? "persistent" : "temporary"}
        open={open}
        onClose={handleDrawerToggle}
      >
        {mainListItems}
      </Drawer>
    </>
  );
};

export default BarraLateral;