import { ReactNode, createContext, useState } from "react";
import { IAccounts } from "../types/Generic/IAccounts";
import { IConfigWpp } from "../types/Wpp/Config/IConfigWpp";
import { IRoles } from "../types/Adm/Role/IRoles";
import { sendGet } from "../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { IModule } from "../types/Adm/Module/IModule";

type GlobalContextType = {
  messages: string[];
  openMessage: boolean;
  loading: boolean;
  configWpp: IConfigWpp;
  selectedAccount: string;
  accounts: IAccounts[];
  roles: IRoles[];
  modules: IModule[];
  changeAccounts: (accs: IAccounts[]) => void;
  changeRoles: (roles: IRoles[]) => void;
  changeModules: (modules: IModule[]) => void;
  changeSelectedAccount: (account: string) => void;
  loadConfigWpp: () => void;
  setaLoading: (loading: boolean) => void;
  closeMessage: () => void;
  addMessage: (message: string) => void;
  addMessages: (messages: string[]) => void;
};

const valorInicialConfigWpp: IConfigWpp = {
  id: 0,
  accountId: 0,
  accountName: "",
  whatsapp: "",
  instanceId: "",
  instanceStatus: "",
  defaultSendPermitted: false,
  createDate: "",
  updateDate: "",
  userCreateId: 0,
  userUpdateId: 0,
  userCreateName: "",
  userUpdateName: "",
};

export const GlobalContext = createContext<GlobalContextType>({
  messages: [],
  openMessage: false,
  loading: false,
  configWpp: valorInicialConfigWpp,
  selectedAccount: "",
  accounts: [],
  roles: [],
  modules: [],
  changeAccounts: () => {},
  changeRoles: () => {},
  changeModules: () => {},
  changeSelectedAccount: () => {},
  loadConfigWpp: () => {},
  setaLoading: () => {},
  closeMessage: () => {},
  addMessage: () => {},
  addMessages: () => {},
});

type GlobalProviderProps = {
  children: ReactNode;
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<string[]>([]);

  const [openMessage, setOpenMessage] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [configWpp, setConfigWpp] = useState<IConfigWpp>(valorInicialConfigWpp);

  const [selectedAccount, setSelectedAccount] = useState<string>("");

  const [accounts, setAccounts] = useState<IAccounts[]>([]);
  const [roles, setRoles] = useState<IRoles[]>([]);
  const [modules, setModules] = useState<IModule[]>([]);

  let navigate = useNavigate();

  const setaLoading = (loading: boolean) => {
    setLoading(loading);
  };

  const loadConfigWpp = async () => {
    let url = `v1/config/wpp`;
    const dados = {};
    try {
      const response = await sendGet<IConfigWpp[]>(url, dados, addMessages, setaLoading, navigate);
      setConfigWpp(response[0]);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const changeSelectedAccount = (account: string) => {
    //console.log("currentAccount mudou para " + account);
    sessionStorage.setItem("currentAccount", account);
    setSelectedAccount(account);
    //console.log(accounts);
    //console.log(account);
    setModules(accounts.find((acc) => acc.accountId.toString() === account)?.modules || []);
  };

  const changeAccounts = (accounts: IAccounts[]) => {
    setAccounts(accounts);
  };
  const changeRoles = (roles: IRoles[]) => {
    setRoles(roles);
  };
  const changeModules = (modules: IModule[]) => {
    setModules(modules);
  };

  const addMessage = (message: string) => {
    //console.log("Contexto")
    //console.log(message)
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const addMessages = (messages: string[]) => {
    setMessages([]);
    for (let message of messages) {
      addMessage(message);
    }
    setOpenMessage(true);
  };

  return (
    <GlobalContext.Provider
      value={{
        messages,
        openMessage,
        loading,
        configWpp,
        selectedAccount,
        accounts,
        roles,
        modules,
        setaLoading,
        closeMessage,
        addMessage,
        addMessages,
        loadConfigWpp,
        changeSelectedAccount,
        changeAccounts,
        changeRoles,
        changeModules,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
