import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import PlanosService from '../../../../services/admin/PlanosService';
import http from '../../../../services/http';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IModuleEditData } from '../../../../types/Adm/Module/IModuleEditData';
import { IPlan } from '../../../../types/Adm/Plan/IPlan';
import { IResponse } from '../../../../types/Generic/IResponse';
import './EdicaoPlanos.css';
import PlanosCheckboxGrid from '../../../../../components/elements/grids/PlanosCheckboxGrid';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IPlanEditData } from '../../../../types/Adm/Plan/IPlanEditData';
import ModulesCheckboxGrid from '../../../../../components/elements/grids/ModulesCheckboxGrid';
import { number } from 'prop-types';

const EdicaoPapeis = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const planId = Number(id);

    const [plano, setPlan] = useState<IPlanEditData>({ id: planId, planName: '', planDescription: '', fixedValue: 0, variableValue: 0, variableMessage: '', modules: [] } as IPlanEditData);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/admin/planos')
    }

    async function handleClickSalvar() {

        let url = `v1/admin/plans/edit/${planId}`
        const dados = plano
        try {
            const response = await sendPost<IPlan>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/admin/planos')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setPlan(prevPlano => ({ ...prevPlano, [name]: valor }));
    };

    async function consultaPlano() {

        let url = `v1/admin/plans/${planId}`
        const dados = {}
        try {
            const response = await sendGet<IPlan>(url, dados, addMessages, setaLoading, navigate);
            setPlan(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleModuloChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
        const target = event.target as HTMLInputElement;
        const valor = target.checked;

        if (valor) {
            setPlan(prevPlano => ({ ...prevPlano, modules: [...prevPlano.modules, module] }));
        } else {
            setPlan(prevPlano => ({ ...prevPlano, modules: prevPlano.modules.filter(module1 => module1.id !== module.id) }));
        }
    };

    useEffect(() => {
        consultaPlano();
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Plano</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Nome" value={plano.planName} name='name' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Descrição" value={plano.planDescription} name='description' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Valor fixo" type= "number" value={plano.fixedValue} name='fixedValue' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Valor Variável" type= "number" value={plano.variableValue} name='variableValue' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Mensagem Variável" value={plano.variableMessage} name='variableMessage' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <ModulesCheckboxGrid selectedModules={plano.modules} handleChange={handleModuloChange} />
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EdicaoPapeis