import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EditBankAccount.css';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { IBankAccount } from '../../../../types/Ceb/IBankAccount';
import { IBankAccountUpdateData } from '../../../../types/Ceb/IBankAccountUpdateData';

const CapEditCategory = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const bankAccountId = Number(id);

    const [bankAccount, setBankAccount] = useState<IBankAccountUpdateData>(
        {
            id: bankAccountId,
            name: '',
        } as IBankAccountUpdateData);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/ceb/bank-account')
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        bankAccount.name = bankAccount.name.trim();
        if (bankAccount.name === '') {
            addMessages(['Nome é obrigatório']);
            return false;
        }

        return true;
    }

    async function handleClickSalvar() {
        if (!validateForm()) {
            return;
        }
        let url = `v1/ceb/bank-account/edit/${bankAccountId}`
        const dados = {
            id: bankAccount.id,
            name: bankAccount.name,
        }
        console.log('dados:');
        console.log(dados);
        try {
            const response = await sendPost<IBankAccount>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/ceb/bank-account')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setBankAccount(prevBankAccount => ({ ...prevBankAccount, [name]: valor }));

    };

    async function searchBankAccount() {

        let url = `v1/ceb/bank-account/${bankAccountId}`
        const dados = {}
        try {
            const response = await sendGet<IBankAccount>(url, dados, addMessages, setaLoading, navigate);
            setBankAccount(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    useEffect(() => {
        setaLoading(true);
        searchBankAccount();
        setaLoading(false);
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Conta</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={bankAccount.name}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default CapEditCategory