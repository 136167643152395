import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";


const iconStyles = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px' // Ajuste o espaçamento entre os ícones

};

interface NotaFiscalActionButtonProps {
    disabled?: boolean;
    title?: string;
    action:  (row: any) => void;
    row: any;
}

export default function NotaFiscalActionButton(props: NotaFiscalActionButtonProps) {
    return (
        <IconButton onClick={() => props.action(props.row)} sx={{ padding: '0px 4px 0px 0px' }} disabled={props.disabled}>
        <Tooltip title={props.title}>
            <SvgIcon fontSize="small">
                <DocumentTextIcon aria-label={props.title} style={iconStyles} />
            </SvgIcon>
        </Tooltip>
    </IconButton>
    );
}