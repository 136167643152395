import AddCardIcon from "@mui/icons-material/AddCard";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../core/context/GlobalContext";
import billsService from "../../../../core/services/admin/BillsService";
import { ICapBill } from "../../../../core/types/Cap/Bill/ICapBill";
import Paginacao from "../../Paginacao";
import { IPaginatedList } from "../../../../core/types/Generic/IPaginatedList";
import { sendPost } from "../../../../core/utils/httpUtils";
import { formataDateLocal } from "../../../../core/utils/date-utils";
import TableHeader from "../../TableHeader";
import CustomTableCell from "../../CustomTableCell";
import { ICapPayment } from "../../../../core/types/Cap/Payment/ICapPayment";
import { CalendarPicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import "./ConfirmDialogCap.css";
import { IBankAccount } from "../../../../core/types/Ceb/IBankAccount";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  initialDueDate: Date;
  finalDueDate: Date;
  search: string;
  selectedStatus: string[];
  refreshFunction: () => void;
}

const ConfirmDialogCap: React.FC<Props> = ({
  initialDueDate: initialDueDate,
  finalDueDate: finalDueDate,
  search: search,
  selectedStatus: selectedStatus,
  refreshFunction: refreshFunction,
}) => {
  const [open, setOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [bankAccountList, setBankAccountList] = useState<IBankAccount[]>([] as IBankAccount[]);

  const [sort, setSort] = useState<string>("p.NAME,asc");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = async () => {
    await billsService.sendPayments(addMessages, setaLoading, navigate, payments);
    refreshFunction();
    searchBill(0, sort, initialDueDate, finalDueDate, search);
    handleClose();
  };

  async function searchBill(
    page: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
    selectedStatus?: string[]
  ) {
    billsService
      .searchFilteredCapBillsWithoutPagination(
        addMessages,
        setaLoading,
        navigate,
        page,
        sort,
        initialDate,
        finalDate,
        searchQuery,
        selectedStatus,
        ["Pago"]
      )
      .then((bills) => {
        if (bills) {
          setTotalPages(bills.totalPages);
          setCurrentPage(page + 1);
          setPayments(
            bills.content.map((bill) => {
              return {
                id: 0,
                suplierName: bill.suplierName,
                suplierCpfCnpj: bill.suplierCpfCnpj,
                bankAccountId: bill.bankAccountId,
                billDescription: bill.billDescription,
                billDate: bill.billDate,
                dueDate: bill.dueDate,
                vlrPagar: bill.vlrPagar,
                paymentValue: bill.vlrPagar,
                paymentDate: bill.dueDate,
                billId: bill.id,
                isTotal: true,
                createDate: "",
                updateDate: "",
                userCreateId: 0,
                userCreateName: "",
                userUpdateId: 0,
                userUpdateName: "",
              };
            })
          );
        }
      });
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchBill(page - 1, sort, initialDueDate, finalDueDate, search);
  };

  const handleChange = (name: string, value: string | number | boolean | Date, index: number) => {
    setPayments((prevPayments) =>
      prevPayments.map((payment, idx) => {
        if (idx !== index) {
          return payment;
        }

        return {
          ...payment,
          [name]: value,
        };
      })
    );
  };

  const { addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [payments, setPayments] = useState<ICapPayment[]>([]);

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
  }

  async function searchBankAccount(bankAccountName: string, bankAccountId: number) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName, id: bankAccountId };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      setBankAccountList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
    console.log(bankAccountList);
  }

  const columns = [
    { displayName: "Fornecedor", columnName: null },
    { displayName: "Descrição", columnName: null },
    { displayName: "Data da fatura", columnName: null },
    { displayName: "Pagamento total", columnName: null },
    { displayName: "Valor pago", columnName: null },
    { displayName: "Data do pagamento", columnName: null },
    { displayName: "Vencimento", columnName: null },
    { displayName: "Conta Bancária", columnName: null },
  ];

  useEffect(() => {
    console.log("busca search");
    console.log(search);
    searchBill(0, sort, initialDueDate, finalDueDate, search);
    searchBankAccount("", 0);
  }, [search, sort, initialDueDate, finalDueDate]);

  return (
    <React.Fragment>
      <Button
        style={{ width: "100px" }}
        color="inherit"
        startIcon={
          <SvgIcon fontSize="small">
            <AddCardIcon />
          </SvgIcon>
        }
        onClick={handleClickOpen}
      >
        Pagar
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Contas à pagar
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickConfirm}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>
        <TableContainer
          component={Paper}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Table size="small" style={{ width: "85%" }}>
            <TableHeader
              initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
              initialSortedField={sort.split(",")[0]}
              columns={columns} // Substitua 'columns' pelo array de colunas que você tem
              handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
            />
            <TableBody>
              {payments.map((row, index) => (
                <TableRow key={row.id}>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      {row.suplierName + " - " + row.suplierCpfCnpj}
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      {row.billDescription}
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      {formataDateLocal(row.billDate + "T00:00:00-03:00")}
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        defaultChecked={row.isTotal}
                        onChange={(event) => handleChange("isTotal", event.target.checked, index)}
                        name="isTotal"
                        value={row.isTotal}
                      />
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="paymentValue"
                        onChange={(event) =>
                          handleChange("paymentValue", event.target.value, index)
                        }
                        required
                        type="number"
                        value={row.paymentValue}
                      />
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      <DatePicker
                        value={row.paymentDate}
                        onChange={(newValue: Date | null) => {
                          setPayments((prevPayments) =>
                            prevPayments.map((payment, idx) => {
                              if (idx !== index) {
                                return payment;
                              }

                              return {
                                ...payment,
                                paymentDate: newValue ? newValue : new Date(),
                              };
                            })
                          );
                        }}
                        renderInput={(params: TextFieldProps) => (
                          <TextField {...params} name="paymentDate" size="small" />
                        )}
                      />
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      {formataDateLocal(row.dueDate + "T00:00:00-03:00")}
                    </Box>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box display="flex" alignItems="center">
                      <Autocomplete
                        disablePortal
                        id="combo-box-bank-account"
                        options={bankAccountList}
                        getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={
                          bankAccountList.find(
                            (bankAccount) => bankAccount.id === row.bankAccountId
                          ) || null
                        } // Defina o valor padrão
                        onChange={(event, newValue) => {
                          setPayments((prevPayments) =>
                            prevPayments.map((payment, idx) => {
                              console.log("index: ", index);
                              console.log("idx: ", idx);
                              if (idx !== index) {
                                return payment;
                              }

                              return {
                                ...payment,
                                ["bankAccountId"]: newValue ? newValue.id : 0,
                              };
                            })
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} style={{ width: 190 }} required />
                        )}
                        onInputChange={(event, newInputValue) => {
                          // searchBankAccount(newInputValue, row.bankAccountId);
                        }}
                      />
                    </Box>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paginacao
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default ConfirmDialogCap;
