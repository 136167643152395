import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Checkbox, Container, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import AccountsCheckboxGrid from '../../../../../components/elements/grids/AccountsCheckboxGrid';
import RolesCheckboxGrid from '../../../../../components/elements/grids/RolesCheckboxGrid';
import { GlobalContext } from '../../../../context/GlobalContext';
import http from '../../../../services/http';
import { IAccount } from '../../../../types/Adm/Account/IAccount';
import { IRole } from '../../../../types/Adm/Role/IRole';
import { IResponse } from '../../../../types/Generic/IResponse';
import { IUser } from '../../../../types/Adm/User/IUser';
import { IAccountEditData } from '../../../../types/Adm/Account/IAccountEditData';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EdicaoContas.css';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IPlan } from '../../../../types/Adm/Plan/IPlan';
import ModulesCheckboxGrid from '../../../../../components/elements/grids/ModulesCheckboxGrid';
import PlanosCheckboxGrid from '../../../../../components/elements/grids/PlanosCheckboxGrid';

const EdicaoContas = () => {

    const { id } = useParams();
    const contaId = Number(id);

    const [conta, setConta] = useState<IAccountEditData>({ id: contaId, name: '', fantasyName: '', cnpj: '', active: true, bloqued: false, modules: [], planos: [] } as IAccountEditData);

    const { addMessages, setaLoading } = useContext(GlobalContext);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/admin/contas')
    }

    async function handleClickSalvar() {
        let url = `v1/admin/accounts/edit/${contaId}`
        const dados = conta
        try {
            const response = await sendPost<IAccount>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/admin/contas');
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;

        let valor = type === 'checkbox' ? target.checked : target.value;

        setConta(prevConta => ({ ...prevConta, [name]: valor }));
    };


    const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
        const target = event.target as HTMLInputElement;
        let valor = target.checked;

        if (valor) {
            setConta(prevConta => ({ ...prevConta, modules: [...prevConta.modules, module] }));
        } else {
            setConta(prevConta => ({ ...prevConta, modules: prevConta.modules.filter(module1 => module1.id !== module.id) }));
        }

    };

    const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>, plano: IPlan) => {
        const target = event.target as HTMLInputElement;
        const valor = target.checked;

        if (valor) {
            setConta(prevConta => ({ ...prevConta, planos: [...prevConta.planos, plano] }));
        } else {
            setConta(prevConta => ({ ...prevConta, planos: prevConta.planos.filter(plan => plan.id !== plano.id) }));
        }
    };

    async function consultaConta() {

        let url = `v1/admin/accounts/${contaId}`
        const dados = {}
        try {
            const response = await sendGet<IAccount>(url, dados, addMessages, setaLoading, navigate);
            setConta(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    useEffect(() => {
        consultaConta();
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Conta</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Nome" value={conta.name} name='name' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Nome Fantasia" value={conta.fantasyName} name='fantasyName' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="CNPJ" value={conta.cnpj} name='cnpj' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={conta.active || false}
                                            onChange={handleChange}
                                            name='active'
                                            value={conta.active || false}
                                        />}
                                    label="Ativa" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={conta.bloqued || false}
                                            onChange={handleChange}
                                            name='bloqued'
                                            value={conta.bloqued || false}
                                        />}
                                    label="Bloqueada" />
                            </Grid>
                            <ModulesCheckboxGrid selectedModules={conta.modules} handleChange={handleModuleChange} />
                            <PlanosCheckboxGrid planosSelecionados={conta.planos} handleChange={handlePlanChange} />

                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EdicaoContas