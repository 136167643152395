import React, { useContext, useState } from 'react';

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import RolesCheckboxGrid from '../../../../../components/elements/grids/RolesCheckboxGrid';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IModuleNewData } from '../../../../types/Adm/Module/IModuleNewData';
import { IRole } from '../../../../types/Adm/Role/IRole';
import { sendPost } from '../../../../utils/httpUtils';
import './NewModule.css';

const NewModule = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const [module, setModule] = useState<IModuleNewData>({ name: '', description: '', roles: [] } as IModuleNewData);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/admin/modules')
    }

    async function handleClickSalvar() {

        let url = `v1/admin/modules/new`
        const dados = module
        try {
            const response = await sendPost<IModule>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/admin/modules')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, papel?: IRole) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = type === 'checkbox' ? target.checked : target.value;
    
        if (type === 'checkbox' && papel) {
            if (valor) {
                setModule(prevModule => ({ ...prevModule, roles: [...prevModule.roles, papel] }));
            } else {
                setModule(prevModule => ({ ...prevModule, roles: prevModule.roles.filter(role => role.id !== papel.id) }));
            }
        } else {
            setModule(prevModule => ({ ...prevModule, [name]: valor }));
        }
    };

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Módulo</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Nome" value={module.name} name='name' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Descrição" value={module.description} name='description' onChange={(event) => handleChange(event)} variant="outlined" fullWidth />
                            </Grid>
                            <RolesCheckboxGrid papeisSelecionados={module.roles} handleChange={handleChange} />
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewModule