import { Button, SvgIcon, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";

import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import { GlobalContext } from "../../../core/context/GlobalContext";
import { sendGet } from "../../../core/utils/httpUtils";
import { useNavigate } from "react-router-dom";

interface Props {
  wppConfigId: number;
  conectado: boolean;
}

const WppQrcode = (props: Props) => {
  const { addMessages, setaLoading, loadConfigWpp } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [qrcode, setQrcode] = useState<string>("");

  const [execucoes, setExecucoes] = useState<number>(0);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const intervalIdRef2 = useRef<NodeJS.Timeout | null>(null);

  async function consultaQrcode(wppConfigId: number) {
    let url = `v1/config/wpp/qrcode/${wppConfigId}`;
    const dados = {};
    try {
      const response = await sendGet<string>(url, dados, addMessages, setaLoading, navigate);
      setQrcode(response);
      setaLoading(false);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaStatus(wppConfigId: number) {
    let url = `v1/config/wpp/status/${wppConfigId}`;
    const dados = {};
    try {
      const response = await sendGet<string>(url, dados, addMessages, setaLoading, navigate);
      if (response === "Conectada") {
        pararIntervalos();
        loadConfigWpp();
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const iniciarIntervalos = () => {
    iniciarIntervalo();
    iniciarIntervalo2();
  };

  const iniciarIntervalo = () => {
    console.log("iniciarIntervalo");
    setExecucoes(0);
    pararIntervalo();

    const id = setInterval(() => {
      consultaQrcode(props.wppConfigId);
      setExecucoes((prevExecucoes) => {
        if (prevExecucoes === 3) {
          pararIntervalos();
        }
        return prevExecucoes + 1;
      });
    }, 18000);

    intervalIdRef.current = id;
  };

  const iniciarIntervalo2 = () => {
    console.log("iniciarIntervalo2");
    pararIntervalo2();

    const id2 = setInterval(() => {
      //console.log("executou2");
      consultaStatus(props.wppConfigId);
    }, 3000);

    intervalIdRef2.current = id2;

    //console.log("intervalIdRef2.current");
    //console.log(intervalIdRef2.current);
  };

  const pararIntervalos = () => {
    //console.log("pararIntervalos");
    pararIntervalo();
    pararIntervalo2();
  };

  const pararIntervalo = () => {
    //console.log("pararIntervalo");
    //console.log(intervalIdRef.current);
    if (intervalIdRef.current) {
      //console.log("parouMESMO");
      //console.log(intervalIdRef.current);
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const pararIntervalo2 = () => {
    //console.log("pararIntervalo2");
    //console.log(intervalIdRef2.current);
    if (intervalIdRef2.current) {
      //console.log("parouMESMO2");
      //console.log(intervalIdRef2.current);
      clearInterval(intervalIdRef2.current);
      intervalIdRef2.current = null;
    }
  };

  useEffect(() => {
    consultaQrcode(props.wppConfigId);
    if (!props.conectado) {
      iniciarIntervalos();
    }

    return pararIntervalos;
  }, []);

  return (
    <div>
      {execucoes <= 3 && qrcode !== null && (
        <img className="qrcode" src={qrcode} alt="QrCode WhatsApp" />
      )}
      {execucoes > 3 && (
        <div style={{ position: "relative", height: "276px", width: "276px" }}>
          <Button
            variant="contained"
            style={{
              position: "absolute",
              bottom: "120px",
              right: "60px",
              zIndex: 100,
            }}
            endIcon={
              <SvgIcon fontSize="small">
                <ArrowPathIcon />
              </SvgIcon>
            }
            onClick={iniciarIntervalos}
          >
            Gerar Qrcode
          </Button>
          <img className="qrcode" src={qrcode} alt="QrCode WhatsApp" style={{ opacity: 0.1 }} />
        </div>
      )}
      {execucoes <= 3 && qrcode === null && (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Aguarde gerando QrCode...
        </Typography>
      )}
    </div>
  );
};

export default WppQrcode;
