import { Paper, TableContainer } from "@mui/material";

interface CustomTableContainerProps {
    children?: React.ReactNode;
}

export default function CustomTableContainer(props: CustomTableContainerProps) {
    return (
        <TableContainer component={Paper}>
            {props.children}
        </TableContainer>
    )
}