import { Autocomplete, Box, Button, Container, Grid, Stack, SvgIcon, TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from 'react';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';



import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICreBill } from '../../../../types/Cre/Bill/ICreBill';
import { IBillNewData } from '../../../../types/Cre/Bill/ICreBillNewData';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IWallet } from '../../../../types/Cre/Wallet/IWallet';
import { sendPost } from '../../../../utils/httpUtils';
import './NewCategory.css';
import { ICreCategoryNewData } from '../../../../types/Cre/Category/ICreCategoryNewData';
import { ICreCategory } from '../../../../types/Cre/Category/ICreCategory';

const NewCategory = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    //Inicia todas as propriedadese do objeto como string vazia
    const [category, setCategory] = useState<ICreCategoryNewData>({
        name: '',
        description: '',
        upperCategoryId: null
    } as ICreCategoryNewData);

    const [categoryList, setCategoryList] = useState<ICreCategory[]>([] as ICreCategory[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cre/category')
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        category.name = category.name.trim();
        category.description = category.description.trim();
        if (category.name === '') {
            addMessages(['Nome é obrigatório']);
            return false;
        }

        return true;
    }
    async function handleClickSave() {
        if (!validateForm()) {
            return;
        }

        let url = `v1/cre/category/new`
        const dados = { 
            name: category.name,
            description: category.description,
            upperCategoryId: category.upperCategoryId
        }
        //console.log(dados)
        try {
            const response = await sendPost<ICreCategory>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cre/category')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchCategories(categoryName: string) {

        let url = `v1/cre/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName }
        try {
            const response = await sendPost<IPaginatedList<ICreCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoryList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setCategory(prevBill => ({ ...prevBill, [name]: valor }));
    };

    useEffect(() => {
        setaLoading(true);
        searchCategories('');
        setaLoading(false);
    }, []);

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Categoria CRE</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nome"
                                    name="name"
                                    value={category.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Descrição"
                                    name="description"
                                    value={category.description}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-category"
                                    options={categoryList}
                                    getOptionLabel={(option) => (option.upperCategoryName ? option.upperCategoryName + ' -> ' : '')+option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setCategory(prevCategory => ({ ...prevCategory, upperCategoryId: newValue.id }));
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria Pai" />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSave} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewCategory;


