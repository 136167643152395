import axios from "axios";

//console.log(process.env.REACT_APP_API_BASE_URL);
console.log(process.env.ACTUAL_ENV);
const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json' //,
    //Content: 'application/json'
  }
})

http.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = sessionStorage.getItem('token')
  const currentAccount = sessionStorage.getItem('currentAccount')

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  //if (currentAccount && config.headers) {
  //  config.headers.ACCOUNT_ID = `${currentAccount}`
  //}

  return config;
}, function (error) {
  // Do something with request error
  console.log('Erro no interceptor do axios')
  return Promise.reject(error);
});


export default http