import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../core/context/GlobalContext';
import planosService from '../../../../core/services/admin/PlanosService';
import { IPlan } from '../../../../core/types/Adm/Plan/IPlan';

interface Props {
    planosSelecionados: IPlan[];
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, plano: IPlan) => void;
}

const PlanosCheckboxGrid: React.FC<Props> = ({ planosSelecionados, handleChange }) => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    let navigate = useNavigate();

    const [planos, setPlanos] = useState<IPlan[]>([]);

    useEffect(() => {
        planosService.consultaTodosPlanos(addMessages, setaLoading, navigate).then(planos => setPlanos(planos));
    }, [])

    return (
        <Grid item xs={12} style={{ padding: '20px' }}>
            <Paper elevation={5} style={{ padding: '20px' }}>
                <Typography variant="h6">Planos</Typography>
                <Grid container spacing={3}>
                    {planos.map((plano, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={planosSelecionados?.some((plano1: { id: number; }) => plano1.id === plano.id)}
                                        onChange={(event) => handleChange(event, plano)}
                                        name='planos[]'
                                        value={plano}
                                    />
                                }
                                label={plano.planName}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
}
export default PlanosCheckboxGrid;