import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../core/context/GlobalContext';
import contasService from '../../../../core/services/admin/ContasService';
import { IAccount } from '../../../../core/types/Adm/Account/IAccount';
import { useNavigate } from 'react-router-dom';

interface Props {
    contasSelecionadas: IAccount[];
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, account: IAccount) => void;
}

const AccountsCheckboxGrid: React.FC<Props> = ({ contasSelecionadas, handleChange }) => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    let navigate = useNavigate();

    const [contas, setContas] = useState<IAccount[]>([]);

    useEffect(() => {
        contasService.consultaTodasContas(addMessages, setaLoading, navigate).then(contas => setContas(contas));
    }, [])

    return (
        <Grid item xs={12} style={{ padding: '20px' }}>
            <Paper elevation={5} style={{ padding: '20px' }}>
                <Typography variant="h6">Contas</Typography>
                <Grid container spacing={3}>
                    {contas.map((conta, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={contasSelecionadas?.some((conta1: { id: number; }) => conta1.id === conta.id)}
                                        onChange={(event) => handleChange(event, conta)}
                                        name='contas[]'
                                        value={conta}
                                    />
                                }
                                label={conta.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
}
export default AccountsCheckboxGrid;