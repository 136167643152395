import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { jwtDecode } from "jwt-decode";
import * as React from 'react';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import { ILogin } from '../../types/Generic/ILogin';
import { IToken } from '../../types/Generic/IToken';
import { sendPost } from '../../utils/httpUtils';
import './Home.css';

const Home = () => {

  const { addMessages, setaLoading } = React.useContext(GlobalContext);

  let navigate = useNavigate();

  const efetuarLogout = () => {
    sessionStorage.removeItem('token')
    navigate('/')
  }

  const aoSubmeterFormular = async (evento: React.FormEvent<HTMLFormElement>) => {

    evento.preventDefault()
    const data = new FormData(evento.currentTarget);
    const usuario = {
      email: data.get('email'),
      password: data.get('password'),
    }

    let url = `v1/users/auth`
    const dados = usuario
    try {
      const response = await sendPost<ILogin>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      const token = response.token;
      const decoded = jwtDecode<IToken>(token);
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('currentAccount', decoded.mainAccId);

      data.set('email', '')
      data.set('password', '')
      navigate('/area-logada/dashboard')
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      efetuarLogout()
    }

  }


  const defaultTheme = createTheme();
  return (<ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={aoSubmeterFormular} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Logar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueceu a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Não tem uma conta? Se inscreva"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

    </Container>
  </ThemeProvider>)
}

export default Home