import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Checkbox, Container, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import RolesCheckboxGrid from '../../../../../components/elements/grids/RolesCheckboxGrid';
import Titulo from '../../../../../components/elements/Titulo';
import http from '../../../../services/http';
import { IRole } from '../../../../types/Adm/Role/IRole';
import { IResponse } from '../../../../types/Generic/IResponse';
import { IUser } from '../../../../types/Adm/User/IUser';
import { IUserEditData } from '../../../../types/Adm/User/IUserEditData';
import './EdicaoUsuarios.css';
import { IAccount } from '../../../../types/Adm/Account/IAccount';
import AccountsCheckboxGrid from '../../../../../components/elements/grids/AccountsCheckboxGrid';
import { GlobalContext } from '../../../../context/GlobalContext';
import { sendGet, sendPost } from '../../../../utils/httpUtils';

const EdicaoUsuarios = () => {

    const { id } = useParams();
    const usuarioId = Number(id);

    const [usuario, setUsuario] = useState<IUserEditData>({ id: usuarioId, mainAccountId: '', name: '', email: '', password: '', confirmPassword: '', active: true, bloqued: false, userRoles: [], userAccounts: [] } as IUserEditData);

    const { addMessages, setaLoading } = useContext(GlobalContext);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/admin/usuarios')
    }

    async function handleClickSalvar() {
        let url = `v1/admin/users/edit/${usuarioId}`
        const dados = usuario
        try {
            const response = await sendPost<IUser>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/admin/usuarios');
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
        
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;

        let valor = type === 'checkbox' ? target.checked : target.value;

        setUsuario(prevUsuario => ({ ...prevUsuario, [name]: valor }));
    };


    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>, papel: IRole) => {
        const target = event.target as HTMLInputElement;
        let valor = target.checked;

        if (valor) {
            setUsuario(prevUsuario => ({ ...prevUsuario, userRoles: [...prevUsuario.userRoles, papel] }));
        } else {
            setUsuario(prevUsuario => ({ ...prevUsuario, userRoles: prevUsuario.userRoles.filter(role => role.id !== papel.id) }));
        }

    };

    const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>, conta: IAccount) => {
        const target = event.target as HTMLInputElement;
        const valor = target.checked;

        if (valor) {
            setUsuario(prevUsuario => ({ ...prevUsuario, userAccounts: [...prevUsuario.userAccounts, conta] }));
        } else {
            setUsuario(prevUsuario => ({ ...prevUsuario, userAccounts: prevUsuario.userAccounts.filter(account => account.id !== conta.id) }));
        }
    };

    async function consultaUsuario() {

        let url = `v1/admin/users/${usuarioId}`
        const dados = {}
        try {
            const response = await sendGet<IUser>(url, dados, addMessages, setaLoading, navigate);
            setUsuario(response)
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    useEffect(() => {
        consultaUsuario();
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Usuário</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Nome" value={usuario.name} name='name' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Email" value={usuario.email} name='email' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Senha" value={usuario.password || ''} name='password' onChange={handleChange} type="password" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Confirmar Senha" value={usuario.confirmPassword || ''} name='confirmPassword' onChange={handleChange} type="password" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={usuario.active || false}
                                            onChange={handleChange}
                                            name='active'
                                            value={usuario.active || false}
                                        />}
                                    label="Ativo" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={usuario.bloqued || false}
                                            onChange={handleChange}
                                            name='bloqued'
                                            value={usuario.bloqued || false}
                                        />}
                                    label="Bloqueado" />
                            </Grid>
                            <RolesCheckboxGrid papeisSelecionados={usuario.userRoles} handleChange={handleRoleChange} />
                            <AccountsCheckboxGrid contasSelecionadas={usuario.userAccounts} handleChange={handleAccountChange} />
                            <Grid item xs={12}>
                                <InputLabel id="demo-simple-select-label">Conta Principal</InputLabel>
                                <Select labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={usuario.mainAccountId}
                                    label="Conta Principal"
                                    name='mainAccountId'
                                    onChange={handleChange}>
                                    {usuario.userAccounts.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>
                                            {account.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>


                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EdicaoUsuarios