
import Pagination from '@mui/lab/Pagination';
import PaginationItem from '@mui/lab/PaginationItem';
import { TableFooter, TableRow } from '@mui/material';
import CustomTableCell from "../CustomTableCell";
import './Paginacao.css';

interface PaginacaoProps {
  totalPages: number,
  currentPage: number,
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void

}

const Paginacao = ({ totalPages, currentPage, handlePageChange }: PaginacaoProps) => {

  return (
    <>
      {totalPages > 1 && (
        <TableFooter>
          <TableRow>
            <CustomTableCell colSpan={6}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                renderItem={(item) => (
                  <PaginationItem
                    component="a"
                    {...item}
                  />
                )}
              />
            </CustomTableCell>
          </TableRow>
        </TableFooter>
      )}
    </>
  )
}

export default Paginacao