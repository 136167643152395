import { TextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface CustomProps {
    name: string;
    label?: string;
    value: number | null;
    fullWidth?: boolean;
    style?: any;
    required?: boolean;
    inputProps?: any;
    onChange: (event: { target: { name: string; value: string } }) => void;
}

  const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(
        function NumericFormatCustom(props, ref): React.ReactElement {
            const { onChange, ...other } = props;
            return (
                <NumericFormat
                    {...other}
                    getInputRef={ref}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        });
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    customInput={TextField}
                    value={props.value}
                    inputProps={props.inputProps}
                    style={props.style}
                    required={props.required}
                />
            );
        },
    );

export default NumericFormatCustom;