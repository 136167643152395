import { TableBody } from "@mui/material";

interface CustomTableBodyProps {
    children?: React.ReactNode;
}

export default function CustomTableBody(props: CustomTableBodyProps) {
    return (
        <TableBody>
            {props.children}
        </TableBody>
    )
}