import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Checkbox, Container, Grid, IconButton, Paper, Stack, SvgIcon, Table, TableBody, TableContainer, TableRow, TextField, TextFieldProps, Tooltip } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ICreBill } from '../../../../types/Cre/Bill/ICreBill';
import { IBillUpdateData } from '../../../../types/Cre/Bill/ICreBillUpdateData';
import { ICreCategory } from '../../../../types/Cre/Category/ICreCategory';
import { ICreClient } from '../../../../types/Cre/Client/ICreClient';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { IModule } from '../../../../types/Adm/Module/IModule';
import { IWallet } from '../../../../types/Cre/Wallet/IWallet';
import { sendDelete, sendGet, sendPost } from '../../../../utils/httpUtils';
import './EditBill.css';
import { ISimpleList } from '../../../../types/Generic/ISimpleList';
import billsService from '../../../../services/admin/BillsService';
import TableHeader from '../../../../../components/elements/TableHeader';
import CustomTableCell from '../../../../../components/elements/CustomTableCell';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import { ICreReceipt } from '../../../../types/Cre/Receipt/ICreReceipt';
import { ISuccessoMensagensResponse } from '../../../../types/Generic/ISuccessoMensagensResponse';
import BankAccount from '../../../Ceb/BankAccount';
import { IBankAccount } from '../../../../types/Ceb/IBankAccount';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const EditBill = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const billId = Number(id);

    const [bill, setBill] = useState<IBillUpdateData>(
        {
            id: billId,
            clientId: 0,
            walletId: 0,
            bankAccountId: 0,
            billDescription: '',
            billDate: new Date(),
            dueDate: new Date(),
            billSerie: '',
            billStatus: '',
            billCode: '',
            categoryId: null,
            vlrReceber: 0,
            receipts: [],
            vlrDevolucao: 0,
            vlrDesconto: 0,
            saldoDuplicata: 0
        } as IBillUpdateData);

    const [clientsList, setClientsList] = useState<ICreClient[]>([] as ICreClient[]);

    const [walletsList, setWalletsList] = useState<IWallet[]>([] as IWallet[]);

    const [categoryList, setCategoryList] = useState<ICreCategory[]>([] as ICreCategory[]);

    const [bankAccountList, setBankAccountList] = useState<IBankAccount[]>([] as IBankAccount[]);

    const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

    const [sort, setSort] = useState<string>("p.NAME,asc");

    const iconStyles = {
        fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
        marginRight: "8px", // Ajuste o espaçamento entre os ícones
    };

    function handleSortChange(sortNovo: string) {
        setSort(sortNovo);
    }

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cre/bill')
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        bill.billSerie = bill.billSerie.trim();
        bill.billCode = bill.billCode.trim();
        if (bill.billSerie === '') {
            addMessages(['Série é obrigatória']);
            return false;
        }
        if (bill.billCode === '') {
            addMessages(['Codigo é obrigatório']);
            return false;
        }
        if (bill.clientId <= 0) {
            addMessages(['Cliente é obrigatório']);
            return false;
        }
        if (bill.walletId <= 0) {
            addMessages(['Carteira é obrigatória']);
            return false;
        }
        if (bill.billStatus === '') {
            addMessages(['Status é obrigatório']);
            return false;
        }
        if (bill.dueDate === undefined) {
            addMessages(['Data de Vencimento é obrigatória']);
            return false;
        }
        if (bill.vlrReceber && bill.vlrReceber <= 0) {
            addMessages(['Valor a Receber deve ser maior que zero']);
            return false;
        }
        if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
            addMessages(['Saldo da Duplicata deve ser maior que zero']);
            return false;
        }
        if (bill.vlrReceber && bill.saldoDuplicata && bill.vlrReceber < bill.saldoDuplicata) {
            addMessages(['Valor a Receber deve ser maior ou igual ao Saldo da Duplicata']);
            return false;
        }
        if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
            addMessages(['Valor de Devolução deve ser maior ou igual a zero']);
            return false;
        }
        if (bill.vlrDesconto && bill.vlrDesconto < 0) {
            addMessages(['Valor de Desconto deve ser maior ou igual a zero']);
            return false;
        }

        return true;
    }

    async function handleClickSalvar() {
        if (!validateForm()) {
            return;
        }
        let url = `v1/cre/bill/edit/${billId}`
        const dados = {
            id: bill.id,
            clientId: bill.clientId,
            walletId: bill.walletId,
            bankAccountId: bill.bankAccountId,
            billDescription: bill.billDescription,
            billSerie: bill.billSerie,
            billStatus: bill.billStatus,
            billCode: bill.billCode,
            billDate: bill.billDate,
            dueDate: bill.dueDate,
            categoryId: bill.categoryId,
            vlrReceber: bill.vlrReceber,
            vlrDevolucao: bill.vlrDevolucao,
            vlrDesconto: bill.vlrDesconto,
            saldoDuplicata: bill.saldoDuplicata,
            receipts: bill.receipts

        }
        console.log("dados");
        console.log(dados);
        try {
            const response = await sendPost<ICreBill>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cre/bill')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchClients(clientName: string, clientId: number) {

        let url = `v1/client/list?size=10&page=0&sort=NAME,asc`;
        const dados = { clientName: clientName, clientId: clientId }
        try {
            const response = await sendPost<IPaginatedList<ICreClient>>(url, dados, addMessages, setaLoading, navigate);
            setClientsList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchReceiptsStatusList() {
        const response = await billsService.searchReceiptsStatusList(addMessages, setaLoading, navigate);
        if (response) {
            setStatusList(response);
        } else {
            addMessages(["Não houve resposta do serviço de faturas"]);
        }
    }

    async function searchWallets(walletName: string, walletId: number) {

        let url = `v1/wallet/list?size=10&page=0&sort=WALLET_NAME,asc`;
        const dados = { walletName: walletName, walletId: walletId }
        try {
            const response = await sendPost<IPaginatedList<IWallet>>(url, dados, addMessages, setaLoading, navigate);
            setWalletsList(response.content);
            console.log(response);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchCategories(categoryName: string, categoryId: number | null) {

        let url = `v1/cre/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName, id: categoryId }
        try {
            const response = await sendPost<IPaginatedList<ICreCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoryList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchBankAccount(bankAccountName: string, bankAccountId: number) {

        let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: bankAccountName, id: bankAccountId }
        try {
            const response = await sendPost<IPaginatedList<IBankAccount>>(url, dados, addMessages, setaLoading, navigate);
            setBankAccountList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
        console.log(bankAccountList);
    }

    const handleChangeReceipts = (name: string, value: string | number | boolean | Date, index: number) => {
        console.log('name');
        console.log(name);
        console.log('value');
        console.log(value);
        console.log('index');
        console.log(index);
        setBill((prevBill) => {
            return {
                ...prevBill,
                receipts:
                    prevBill.receipts.map((receipt, idx) => {
                        if (idx !== index) {
                            return receipt;
                        }
                        return {
                            ...receipt,
                            [name]: value,
                        };
                    })
            }
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setBill(prevBill => ({ ...prevBill, [name]: valor }));

    };

    const handleClickDelete = (
        receiptToDelete: ICreReceipt) => {
        if (
            confirm(
                `Tem certeza que deseja excluir o recebimento`
            )
        ) {
            /*let indexToDelete = null;
            bill.receipts.map((receipt, idx) => {
                if (receipt.id === receiptToDelete.id) {
                    indexToDelete = idx;
                }
            });
            console.log(indexToDelete);
            if(indexToDelete) {
                bill.receipts.splice(indexToDelete, 1);
            }*/
            setBill((prevBill) => {
                return {
                    ...prevBill,
                    receipts: prevBill.receipts.filter((receipt) => receipt.id !== receiptToDelete.id),
                };
            });
        }
        return;
    };

    async function searchBill() {

        let url = `v1/cre/bill/${billId}`
        const dados = {}
        try {
            const response = await sendGet<ICreBill>(url, dados, addMessages, setaLoading, navigate);
            console.log("response: ");
            console.log(response);
            setBill(response)
            searchClients('', response.clientId);
            searchWallets('', response.walletId);
            searchCategories('', response.categoryId);
            searchBankAccount('', response.bankAccountId);
            searchReceiptsStatusList();
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    useEffect(() => {
        setaLoading(true);
        searchBill();
        searchReceiptsStatusList();
        searchBankAccount('', 0);
        setaLoading(false);
    }, [])

    const columns = [
        { displayName: "Recebimento total", columnName: null },
        { displayName: "Valor pago", columnName: null },
        { displayName: "Data do pagamento", columnName: null },
        { displayName: "Ações", columnName: null },
    ];

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Fatura à Receber</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-client"
                                    options={clientsList}
                                    getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={clientsList.find(client => client.id === bill.clientId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "clientId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Cliente" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchClients(newInputValue, bill.clientId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-wallet"
                                    options={walletsList}
                                    getOptionLabel={(option) => option.walletNumber + " - " + option.walletName} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={walletsList.find(wallet => wallet.id === bill.walletId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "walletId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Carteira" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchWallets(newInputValue, bill.walletId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-status"
                                    options={statusList}
                                    getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={statusList.find(billStatus => billStatus.label === bill.billStatus) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "billStatus": newValue?.label ? newValue.label : ''
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Status" required />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-bank-account"
                                    options={bankAccountList}
                                    getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={bankAccountList.find(bankAccount => bankAccount.id === bill.bankAccountId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "bankAccountId": newValue ? newValue.id : 0
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Conta Bancária" required />}
                                    onInputChange={(event, newInputValue) => {
                                        searchBankAccount(newInputValue, bill.bankAccountId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="billDescription"
                                    onChange={handleChange}
                                    required

                                    value={bill.billDescription} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Série"
                                    name="billSerie"
                                    onChange={handleChange}
                                    required
                                    value={bill.billSerie}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Codigo"
                                    name="billCode"
                                    onChange={handleChange}
                                    required
                                    value={bill.billCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Data fatura"
                                    value={bill.billDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "billDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='billDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    label="Vencimento fatura"
                                    value={bill.dueDate}
                                    onChange={(newValue: Date | null) => {
                                        setBill(prevBill => ({ ...prevBill, "dueDate": newValue ? newValue : new Date() }));
                                    }}
                                    renderInput={(params: TextFieldProps) => <TextField {...params} style={{ width: 245 }} name='dueDate' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-category"
                                    options={categoryList}
                                    getOptionLabel={(option) => option.name} // Use o nome da categoria como label
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={categoryList.find(category => category.id === bill.categoryId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setBill(prevBill => ({
                                            ...prevBill,
                                            "categoryId": newValue ? newValue.id : null
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria" />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue, bill.categoryId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Valor à Receber"
                                    name="vlrReceber"
                                    onChange={handleChange}
                                    required
                                    type="number"
                                    value={bill.vlrReceber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Valor de Devolução"
                                    name="vlrDevolucao"
                                    onChange={handleChange}
                                    type="number"
                                    value={bill.vlrDevolucao}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Valor de Desconto"
                                    name="vlrDesconto"
                                    onChange={handleChange}
                                    type="number"
                                    value={bill.vlrDesconto}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Saldo da Duplicata"
                                    name="saldoDuplicata"
                                    onChange={handleChange}
                                    required
                                    type="number"
                                    value={bill.saldoDuplicata}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TableContainer
                                            component={Paper}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Table size="small" style={{ width: "85%" }}>
                                                <TableHeader
                                                    initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                                                    initialSortedField={sort.split(",")[0]}
                                                    columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                                                    handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                                                />
                                                <TableBody>
                                                    {bill.receipts.map((row, index) => (
                                                        <TableRow key={row.id}>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <Checkbox
                                                                        defaultChecked={row.isTotal}
                                                                        onChange={(event) =>
                                                                            handleChangeReceipts("isTotal", event.target.checked, index)
                                                                        }
                                                                        name="isTotal"
                                                                        value={row.isTotal}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <TextField
                                                                        name="receiptValue"
                                                                        onChange={(event) =>
                                                                            handleChangeReceipts("receiptValue", event.target.value, index)
                                                                        }
                                                                        required
                                                                        type="number"
                                                                        value={row.receiptValue}
                                                                        style={{
                                                                            width: 120,
                                                                        }}
                                                                        inputProps={{
                                                                            style: { paddingTop: 5, paddingBottom: 5 },
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <Box display="flex" alignItems="center">
                                                                    <DatePicker
                                                                        value={row.receiptDate}
                                                                        onChange={(newValue: Date | null) => {
                                                                            setBill((prevBill) => {
                                                                                return {
                                                                                    ...prevBill,
                                                                                    receipts: bill.receipts.map((receipt, idx) => {
                                                                                        if (idx !== index) {
                                                                                            return receipt;
                                                                                        }

                                                                                        return {
                                                                                            ...receipt,
                                                                                            receiptDate: newValue ? newValue : new Date(),
                                                                                        };
                                                                                    })
                                                                                }

                                                                            });
                                                                        }
                                                                        }
                                                                        renderInput={(params: TextFieldProps) => (
                                                                            <TextField
                                                                                {...params}
                                                                                style={{ width: 140 }}
                                                                                name="receiptDate"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                <IconButton onClick={() => handleClickDelete(row)}>
                                                                    <Tooltip title="Excluir">
                                                                        <SvgIcon fontSize="small">
                                                                            <TrashIcon aria-label="Excluir" style={iconStyles} />
                                                                        </SvgIcon>
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default EditBill