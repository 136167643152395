import { Outlet } from "react-router-dom"
import Rodape from "../../../components/layouts/Rodape"
import { Box } from "@mui/material"

const PaginaBase = () => {

    return (<main>
        <Box mb={15}> {/* Adiciona uma margem inferior de 4 unidades */}
          <Outlet />
        </Box>
        <Rodape />
      </main>)
}

export default PaginaBase