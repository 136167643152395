import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as AsaasIcon } from '../../../assets/img/asaas.svg';

interface AsaasActionButtonProps {
    children?: React.ReactNode;
    handleClickSendToAsaas: (row: any) => void;
    row: any;
}

const iconStylesDisabled = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px', // Ajuste o espaçamento entre os ícones
    opacity: 0.1
};

export default function AsaasActionButton(props: AsaasActionButtonProps) {
    return (
        <>
            {props.row.asaasId && (
                <IconButton onClick={() => void (null)} sx={{padding: '0px 4px 0px 0px'}}>
                    <Tooltip title={props.row.asaasId} >
                        <AsaasIcon aria-label={props.row.asaasId} style={{ width: '20px', height: '20px', fontSize: '20px', marginRight: '8px', padding: '0' }} />
                    </Tooltip>
                </IconButton>)}
            {!props.row.asaasId && (
                <IconButton onClick={() => props.handleClickSendToAsaas(props.row)} style={iconStylesDisabled} sx={{padding: '0px 4px 0px 0px'}}>
                    <AsaasIcon aria-label={props.row.asaasId} style={{ width: '20px', height: '20px', fontSize: '20px', marginRight: '8px', padding: '0'}} />
                </IconButton>)}
        </>
    );
}