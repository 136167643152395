import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import RolesCheckboxGrid from '../../../../../components/elements/grids/RolesCheckboxGrid';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IMenu } from '../../../../types/Adm/Menu/IMenu';
import { IMenuNewData } from '../../../../types/Adm/Menu/IMenuNewData';
import { IRole } from '../../../../types/Adm/Role/IRole';
import { sendPost } from '../../../../utils/httpUtils';
import './InclusaoMenus.css';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';

const NewMenu = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const [menu, setMenu] = useState<IMenuNewData>({ menuTitle: '', menuPath: '', menuIcon: '', menuOrder: null, menuParentId: null, roles: [] } as IMenuNewData);

    const [menuList, setMenuList] = useState<IMenu[]>([] as IMenu[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/admin/menus')
    }

    async function handleClickSalvar() {

        let url = `v1/admin/menus/new`
        const dados = menu
        try {
            const response = await sendPost<IMenu>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/admin/menus')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    async function searchMenus(menuName: string) {

        let url = `v1/admin/menus/list?size=10&page=0&sort=MENU_ORDER,asc`;
        const dados = { menuTitle: menuName }
        try {
            const response = await sendPost<IPaginatedList<IMenu>>(url, dados, addMessages, setaLoading, navigate);
            setMenuList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, papel?: IRole) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = type === 'checkbox' ? target.checked : target.value;

        if (type === 'checkbox' && papel) {
            if (valor) {
                setMenu(prevMenu => ({ ...prevMenu, roles: [...prevMenu.roles, papel] }));
            } else {
                setMenu(prevMenu => ({ ...prevMenu, roles: prevMenu.roles.filter(role => role.id !== papel.id) }));
            }
        } else {
            setMenu(prevMenu => ({ ...prevMenu, [name]: valor }));
        }
    };

    useEffect(() => {
        setaLoading(true);
        searchMenus('');
        setaLoading(false);
    }, []);

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Incluir Menu</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField label="Título" value={menu.menuTitle} name='menuTitle' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Rota" value={menu.menuPath} name='menuPath' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Ícone" value={menu.menuIcon} name='menuIcon' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Ordem" type='number' value={menu.menuOrder} name='menuOrder' onChange={handleChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-menu"
                                    options={menuList}
                                    getOptionLabel={(option) => (option.menuParentTitle ? option.menuParentTitle + ' -> ' : '')+option.menuTitle}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setMenu(prevMenu => ({ ...prevMenu, menuParentId: newValue.id }));
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Menu Pai" />}
                                    onInputChange={(event, newInputValue) => {
                                        searchMenus(newInputValue);
                                    }}
                                />
                            </Grid>
                            <RolesCheckboxGrid papeisSelecionados={menu.roles} handleChange={handleChange} />

                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default NewMenu