import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Box, Button, Container, Grid, Stack, SvgIcon, TextField } from '@mui/material';

import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import CloudArrowUpIcon from '@heroicons/react/24/solid/CloudArrowUpIcon';


import { useNavigate, useParams } from 'react-router-dom';
import Titulo from '../../../../../components/elements/Titulo';
import { GlobalContext } from '../../../../context/GlobalContext';
import { sendGet, sendPost } from '../../../../utils/httpUtils';
import './EditCategory.css';
import { IPaginatedList } from '../../../../types/Generic/IPaginatedList';
import { ICapCategory } from '../../../../types/Cap/Category/ICapCategory';
import { ICapCategoryUpdateData } from '../../../../types/Cap/Category/ICapCategoryUpdateData';

const CapEditCategory = () => {

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const { id } = useParams();
    const categoryId = Number(id);

    const [category, setCategory] = useState<ICapCategoryUpdateData>(
        {
            id: categoryId,
            name: '',
            description: '',
            upperCategoryId: null
        } as ICapCategoryUpdateData);

    const [categoryList, setCategoryList] = useState<ICapCategory[]>([] as ICapCategory[]);

    let navigate = useNavigate();
    function handleClickVoltar() {
        navigate('/area-logada/cap/category')
    }

    function validateForm() {
        //Aplicar trim em todos os campos
        category.name = category.name.trim();
        category.description = category.description.trim();
        if (category.name === '') {
            addMessages(['Nome é obrigatório']);
            return false;
        }

        return true;
    }

    async function handleClickSalvar() {
        if (!validateForm()) {
            return;
        }
        let url = `v1/cap/category/edit/${categoryId}`
        const dados = {
            id: category.id,
            name: category.name,
            description: category.description,
            upperCategoryId: category.upperCategoryId
        }
        console.log('dados:');
        console.log(dados);
        try {
            const response = await sendPost<ICapCategory>(url, dados, addMessages, setaLoading, navigate);
            navigate('/area-logada/cap/category')
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function searchCategories(categoryName: string, categoryId: number | null) {

        let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
        const dados = { name: categoryName, id: categoryId}
        try {
            const response = await sendPost<IPaginatedList<ICapCategory>>(url, dados, addMessages, setaLoading, navigate);
            setCategoryList(response.content);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, type } = target;
        let valor = target.value;

        setCategory(prevCategory => ({ ...prevCategory, [name]: valor }));

    };

    async function searchCategory() {

        let url = `v1/cap/category/${categoryId}`
        const dados = {}
        try {
            const response = await sendGet<ICapCategory>(url, dados, addMessages, setaLoading, navigate);
            setCategory(response)
            searchCategories('', response.upperCategoryId);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }
    }

    useEffect(() => {
        setaLoading(true);
        searchCategory();
        
        setaLoading(false);
    }, [])

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Titulo>Editar Fatura à Receber</Titulo>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <ChevronLeftIcon />
                                            </SvgIcon>
                                        )}
                                        onClick={handleClickVoltar}>
                                        Voltar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={category.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descrição"
                                    name="description"
                                    onChange={handleChange}
                                    value={category.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-category"
                                    options={categoryList}
                                    getOptionLabel={(option) => (option.upperCategoryName ? option.upperCategoryName + ' -> ' : '') + option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: 300 }}
                                    value={categoryList.find(cat => cat.id === category.upperCategoryId) || null} // Defina o valor padrão
                                    onChange={(event, newValue) => {
                                        setCategory(prevCategory => ({
                                            ...prevCategory,
                                            "upperCategoryId": newValue ? newValue.id : null
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categoria Pai" />}
                                    onInputChange={(event, newInputValue) => {
                                        searchCategories(newInputValue, category.upperCategoryId);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={handleClickSalvar} variant="contained" color="primary" fullWidth startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CloudArrowUpIcon />
                                    </SvgIcon>
                                )}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </React.Fragment >
    )
}

export default CapEditCategory