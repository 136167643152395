

import React, { useContext, useEffect, useState } from "react";

import './Contacts.css';


import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';

import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon';

import XCircleIcon from '@heroicons/react/24/solid/XCircleIcon';

import PlusIcon from '@heroicons/react/24/solid/PlusIcon';

import { Box, Button, Container, IconButton, Paper, Stack, SvgIcon, Table, TableBody, TableContainer, TableHead, TableRow, styled } from "@mui/material";

import { useNavigate } from "react-router-dom";
import CustomTableCell from '../../../../components/elements/CustomTableCell';
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from '../../../../components/elements/Titulo';
import { GlobalContext } from '../../../context/GlobalContext';
import { IContact } from '../../../types/Wpp/Contact/IContact';
import { IPaginatedList } from '../../../types/Generic/IPaginatedList';
import { ISuccessoMensagensResponse } from '../../../types/Generic/ISuccessoMensagensResponse';
import { formataTimeStampLocal } from '../../../utils/date-utils';
import { sendPost } from "../../../utils/httpUtils";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import TableHeader from "../../../../components/elements/TableHeader";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CheckedActionButton from "../../../../components/elements/CheckedActionButto";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Contacts = () => {

  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [contacts, setContacts] = useState<IContact[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("sp.NAME,asc");

  const iconStyles = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px' // Ajuste o espaçamento entre os ícones

  };
  const iconStylesDisabled = {
    fontSize: '24px', // Ajuste o tamanho dos ícones conforme necessário
    marginRight: '8px', // Ajuste o espaçamento entre os ícones
    opacity: 0.1

  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  async function consultaContacts(page: number, sort?: string) {

    let url = `v1/wpp/contacts/list?size=10&page=${page}${sort ? `&sort=${sort}` : ""}`
    const dados = {}
    try {
      const response = await sendPost<IPaginatedList<IContact>>(url, dados, addMessages, setaLoading, navigate);
      setTotalPages(response.totalPages)
      setContacts(response.content)
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }


  useEffect(() => {
    consultaContacts(0);
  }, [])


  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    consultaContacts(page - 1);
  };

  async function handleClickChangePermitted(row: IContact): Promise<void> {

    let url = `v1/wpp/contacts/update-send-permitted/${row.id}`
    const dados = {}
    try {
      const response = await sendPost<IPaginatedList<ISuccessoMensagensResponse>>(url, dados, addMessages, setaLoading, navigate);
      consultaContacts(currentPage - 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }

  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    consultaContacts(currentPage - 1, sortNovo);
  }

  const columns = [
    { displayName: "Nome Contato", columnName: "sp.NAME" },
    { displayName: "Telefone Contato", columnName: "wc.WHATSAPP" },
    { displayName: "Envio Permitido", columnName: "wc.SEND_PERMITTED" },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Titulo>Contatos</Titulo>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <Button
                    color="inherit"
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    )}
                    disabled>
                    Importar
                  </Button>
                  <Button disabled
                    color="inherit"
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    )}
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  disabled>
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {contacts.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>{row.peopleName}</CustomTableCell>
                      <CustomTableCell>{row.whatsapp}</CustomTableCell>
                      <CustomTableCell>
                        <CheckedActionButton
                          row={row}
                          isChecked={row.sendPermitted}
                          handleClickChangeChecked={handleClickChangePermitted} />
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
                <Paginacao
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange} />
              </CustomTable>
            </CustomTableContainer>
          </Stack>
        </Container>
      </Box>
    </React.Fragment >
  )
}

export default Contacts