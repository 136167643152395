import { ReactNode } from "react";
import "./BotaoNavegacao.css";
import { Box, ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";

interface BotaoNavegacaoProps {
  active: boolean;
  disabled: boolean;
  external: boolean;
  icon: ReactNode;
  path: string;
  title: string;
  children?: ReactNode;
}

const BotaoNavegacao = ({ active, disabled, external, icon, path, title }: BotaoNavegacaoProps) => {
  const linkProps = path
    ? external
      ? {
          component: "a",
          href: path,
          target: "_blank",
        }
      : {
          component: Link,
          to: path,
        }
    : {};

  return (
    <ButtonBase
      sx={{
        alignItems: "center",
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
        pl: "16px",
        pr: "16px",
        py: "6px",
        textAlign: "left",
        width: "100%",
        height: "25px",
      }}
      {...linkProps}
    >
      {icon && (
        <Box
          component="span"
          sx={{
            alignItems: "center",
            color: "neutral.400",
            display: "inline-flex",
            justifyContent: "center",
            mr: 2,
            ...(active && {
              color: "primary.main",
            }),
          }}
        >
          {icon}
        </Box>
      )}
      <Box
        component="span"
        sx={{
          color: "neutral.400",
          flexGrow: 1,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "24px",
          whiteSpace: "nowrap",
          ...(active && {
            color: "common.white",
          }),
          ...(disabled && {
            color: "neutral.500",
          }),
        }}
      >
        {title}
      </Box>
    </ButtonBase>
  );
};

export default BotaoNavegacao;
