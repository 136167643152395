import { NavigateFunction } from "react-router-dom";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { sendGet, sendPost } from "../../../utils/httpUtils";
import { ICapBill } from "../../../types/Cap/Bill/ICapBill";
import { ICapPayment } from "../../../types/Cap/Payment/ICapPayment";
import { ISimpleList } from "../../../types/Generic/ISimpleList";
import { FormLabel } from "@mui/material";
import { id } from "date-fns/locale";
import { ICreReceipt } from "../../../types/Cre/Receipt/ICreReceipt";
import { ICreBill } from "../../../types/Cre/Bill/ICreBill";
import BankAccount from "../../../pages/Ceb/BankAccount";

const billsService = {
  searchAllBills: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction
  ) {
    let url = `v1/cap/bill/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<ICapBill>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  searchFilteredCapBills: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    page: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
    selectedStatus?: string[],
    notSelectedStatus?: string[]
  ) {
    let url = `v1/cap/bill/list?size=5&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: searchQuery,
      billStatus: selectedStatus,
      notBillStatus: notSelectedStatus,
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<ICapBill>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  searchFilteredCapBillsWithoutPagination: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    page: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
    selectedStatus?: string[],
    notSelectedStatus?: string[]
  ) {
    let url = `v1/cap/bill/list?size=1000&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: searchQuery,
      billStatus: selectedStatus,
      notBillStatus: notSelectedStatus,
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<ICapBill>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  searchPaymentsStatusList: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction
  ) {
    let url = `v1/cap/bill/possible-bill-status`;
    const dados = {};
    try {
      const response = await sendGet<ISimpleList[]>(url, dados, addMessages, setaLoading, navigate);
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  searchReceiptsStatusList: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction
  ) {
    let url = `v1/cre/bill/possible-bill-status`;
    const dados = {};
    try {
      const response = await sendGet<ISimpleList[]>(url, dados, addMessages, setaLoading, navigate);
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  checkBills: function (billsToCheck: String[], bills: ICapBill[]) {
    console.log(bills);
    console.log(billsToCheck);
    if (bills.some((bill) => billsToCheck.includes(bill.billCode))) {
      return true;
    }
    return false;
  },

  sendPayments: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    payments: ICapPayment[]
  ) {
    let url = `v1/cap/bill/payments`;
    const dados = JSON.parse(
      JSON.stringify(
        payments.map((payment) => {
          return {
            paymentValue: payment.paymentValue,
            paymentDate: payment.paymentDate,
            isTotal: payment.isTotal,
            capBillId: payment.billId,
            bankAccountId: payment.bankAccountId
          };
        })
      )
    );
    console.log(dados);
    try {
      const response = await sendPost<ICapPayment[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  searchFilteredCreBillsWithoutPagination: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
    selectedStatus?: string[],
    notSelectedStatus?: string[]
  ) {
    let url = `v1/cre/bill/list?size=1000&page=0${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: searchQuery,
      billStatus: selectedStatus,
      notBillStatus: notSelectedStatus,
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<ICreBill>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  sendReceipts: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    receipts: ICreReceipt[]
  ) {
    let url = `v1/cre/bill/receipts`;
    const dados = JSON.parse(
      JSON.stringify(
        receipts.map((receipts) => {
          return {
            bankAccountId: receipts.bankAccountId,
            receiptValue: receipts.receiptValue,
            receiptDate: receipts.receiptDate,
            isTotal: receipts.isTotal,
            billId: receipts.billId,
          };
        })
      )
    );
    console.log(dados);
    try {
      const response = await sendPost<ICreReceipt[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },
};

export default billsService;
