import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { sendGet } from "../../../core/utils/httpUtils";
import { GlobalContext } from "../../../core/context/GlobalContext";

const packageJson = require('../../../../package.json');

const SystemVersion = (props: any) => {

    let navigate = useNavigate();

    const { addMessages, setaLoading } = useContext(GlobalContext);

    const [backVersion, setBackVersion] = React.useState<String>(" ");
    const [frontVersion, setFrontVersion] = React.useState<String>(" ");

    const [backEnv, setBackEnv] = React.useState<String>(" ");
    const [frontEnv, setFrontEnv] = React.useState<String>(" ");

    const [backEnvColor, setBackEnvColor] = React.useState<string>(" ");
    const [frontEnvColor, setFrontEnvColor] = React.useState<string>(" ");

    async function loadVersion() {

        let url = `v1/system/version`
        const dados = {}
        try {
            const response = await sendGet<String>(url, dados, addMessages, setaLoading, navigate);
            setBackVersion(response);
            setFrontVersion(packageJson.version);
        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    async function loadEnv() {

        let url = `v1/system/env`
        const dados = {}
        try {
            const response = await sendGet<String>(url, dados, addMessages, setaLoading, navigate);
            switch (response) {
                case "dev":
                    setBackEnvColor("#0F0");
                    break;
                case "qas":
                    setBackEnvColor("#00F");
                    break;
                case "prd":
                    setBackEnvColor("#F00");
                    break;
                default:
                    setBackEnvColor("#000");
                    break;
            }
            setBackEnv(response);
            const frontEnv = process.env.REACT_APP_ACTUAL_ENV || ' ';
            const reactAppApiBaseUrl = process.env.REACT_APP_API_BASE_URL || ' ';
            //console.log("frontEnv: " + frontEnv);
            //console.log("reactAppApiBaseUrl: " + reactAppApiBaseUrl);
            switch (frontEnv) {
                case "dev":
                    setFrontEnvColor("#0F0");
                    break;
                case "qas":
                    setFrontEnvColor("#00F");
                    break;
                case "prd":
                    setFrontEnvColor("#F00");
                    break;
                default:
                    setFrontEnvColor("#000");
                    break;
            }
            setFrontEnv(frontEnv);

        } catch (error) {
            // Handle the error here if necessary
            console.log(error);
        }

    }

    useEffect(() => {
        loadVersion();
        loadEnv();
    }, [])

    return (
        <Box
            sx={{ mt: 8, mb: 4 }} display="flex" alignItems="center" {...props}>
            <Typography variant="body2" color="text.secondary">&nbsp;</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ color: backEnvColor }}>{backVersion}</Typography>
            <Typography variant="body2" color="text.secondary">&nbsp;/&nbsp;</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ color: frontEnvColor }}>{frontVersion}</Typography>
        </Box>
    )
}

export default SystemVersion