import { Box } from "@mui/material";

interface CustomActionBoxProps {
    children?: React.ReactNode;
}

export default function CustomActionBox(props: CustomActionBoxProps) {
    return (
        <Box display="flex" alignItems="center">
           {props.children}
        </Box>
    );
}